<template>
  <main>
    <div class="container" style="text-align: left">
      <br />
      <br />
      <img
        class="mb-4"
        src="https://www.devalayas.in/assets/images/can_pol.png"
        alt=""
      />
      <br />
      <div class="text-justify">
        <p>&nbsp;</p>
        <h5>Cancellation Policy:</h5>
        <br />
        Cancellation/Refund Policy: We would like to provide you with
        information regarding our cancellation and refund policy for service
        orders. We understand that circumstances may arise where you need to
        cancel puja services or any other services that have been ordered
        according to the organized schedule selected by you. We have established
        the following refund policy: Cancellation till before 1 day: Devotee
        cancels the booked Puja, which is not yet accepted by the Pandit / BDA
        then, the Devalaya will refund 100% of the amount, excluding a 5%
        transaction charges within 2 working days. Cancellation on the puja day:
        Devotee wishes to cancel the Puja on the Puja date, which is not yet
        accepted by the Pandit /BDA, then Devalaya will refund 50% of the
        amount, excluding a 5% transaction charges within 2 working days.
        Cancellation after pandit accepts puja: Unfortunately, no refund can be
        approved in this case, as all the necessary bookings would have already
        been made. Special puja cancellation: In the event that the allotted
        pandit arrives at the venue on time, but the puja cannot be conducted
        during the specified time (Muhurat) due to reasons from the customer's
        side, we are unable to provide a refund. Consultation charges: Please
        note that consultation charges are non-refundable, regardless of whether
        the client has performed the puja/astrology or not. Transaction charges:
        Before initiating the refund, we will deduct the transaction charges 5%
        from the refund amount. Pooja Kits: Refunds for puja kits will only be
        applicable if any damage is caused by Devalaya. If you cancel the order
        within 1 day of placing it, a 100% refund will be initiated. No refund
        requests will be accepted after 1 day. We hope this policy clarifies the
        terms and conditions related to cancellations and refunds. If you have
        any further questions or concerns, please don't hesitate to reach out to
        us.
        <p>&nbsp;</p>
        <h5>Automatic Cancellation:</h5>
        <br />

        We understand that sometimes circumstances may arise which lead to the
        automatic cancellation of the puja. In such cases, Devalaya will notify
        you within 2 working days regarding the cancellation and the approval of
        your refund. Please note that refunds will be processed within 2 working
        days, subject to our Refund Policy.
        <p>&nbsp;</p>
        If you have not yet received your refund, we kindly suggest the
        following steps: Check your bank account: Please verify if the refund
        has been credited to your bank account. Contact your credit card
        company: It is advisable to reach out to your credit card company as
        there might be a processing time before the refund is officially posted.
        Contact your bank: In some cases, there may be a slight delay in the
        processing of refunds. It is recommended to contact your bank for
        further information.
        <p>&nbsp;</p>
        If you have followed these steps and have not received your refund,
        please feel free to contact us at devalayas.in@gmail.com or
        info@devalayas.com. We are here to assist you. Additionally, we offer
        the option for clients to opt for a credit or to their Devalaya account,
        which can be adjusted for subsequent bookings.
        <p>&nbsp;</p>
        <h5>Full Refund Reasons:</h5>
        <br />

        We would like to inform you that a full refund will be provided in the
        following situations: Non-appearance of the Pandit: If the Pandit fails
        to turn up or there is a no-show for performing the puja. Software
        issues/glitches: In rare cases where payment has been made but the
        booking is not confirmed due to software issues or glitches, please send
        an email with the attached screenshot of puja history and payment to our
        support team with the reason for approval. Once our support team
        confirms the situation, our backend team will try to fix it. If the puja
        date is passed a puja reschedule or full refund will be processed for
        you as per your choice. We strive to provide the best service possible
        and ensure a smooth refund process for our valued customers. If you have
        any further questions or concerns, please do not hesitate to contact us.
        <p>&nbsp;</p>
        <h5>Support and Complaint Handling :</h5>
        <br />

        We are committed to providing support and addressing any concerns or
        issues you may have. Please find below the subjects for which our
        support is available:
        <p>&nbsp;</p>
        If the Pandit or Astrologer does not show up for the puja:
        <p>&nbsp;</p>
        If the pandit fails to turn up and does not perform the puja, we will
        refund the amount to you as per our Auto Cancellation Policy, once it is
        confirmed that the puja was not performed. In the case of the Pandit not
        showing up, please inform us on call during the time of consultation,
        and we will investigate and refund the amount accordingly.
        <p>&nbsp;</p>
        If the temple is closed due to some reason: If the pandit accepts the
        puja but does not respond or perform the puja, the puja amount will be
        credited back to you within 2 working days from the date of
        confirmation. If the pandit does not accept the puja, we will confirm
        with the pandit, inform you, and reschedule the puja.
        <p>&nbsp;</p>
        If the amount is deducted, but the puja request is not created: Please
        contact our support team with screenshots of the puja history page and
        the deducted amount/payment. Our backend team will investigate and
        resolve the issue within 2 to 6 hours.
        <p>&nbsp;</p>
        If you receive the wrong video: If you receive an incorrect video,
        please contact our team and report the issue against the pandit or BDA.
        We will ensure that appropriate action is taken, and the amount will be
        refunded to you within 2 working days from the date of report.
        <p>&nbsp;</p>
        If you are unable to book a puja: We have uploaded puja booking videos
        on YouTube for your reference. If you still encounter issues, please
        contact our support team and we will provide you with proper assistance.
        <p>&nbsp;</p>
        If you have not received the prasadam: We will ensure that prasadam is
        either delivered from the temple or personally couriered by our Devalaya
        team to you with a proper address added while booking.
        <p>&nbsp;</p>
        If a special puja is booked, and the pandit is not on the live call. We
        will ensure that a suitable special pandit is assigned. If there are no
        network issues at your location, we will take appropriate action,
        including arranging an alternative pandit if necessary.
        <p>&nbsp;</p>
        The process after the puja is booked and how it works: You can refer to
        the video tutorial available on YouTube or check the puja history for
        updates on the progress of your puja request.
        <p>&nbsp;</p>
        If you are unable to find your temple in the Devalaya app or it is not
        added: There is an option in the app to add your temple. If you cannot
        find the temple, please use this feature to add it.
        <p>&nbsp;</p>
        What is dakshina? Dakshina refers to the amount offered to the pandit by
        the devotee, and it will be directly credited to the pandit.
        <p>&nbsp;</p>
        Can I include the names of family members in the puja? Yes, you can
        include the names of up to two family members in the puja.
        <p>&nbsp;</p>
        How do you know if the puja is only for you and not combined? The puja
        will be combined, and we do not guarantee that pujas like
        Abhishek/Rudrabhishek etc. at the temple will be performed exclusively
        for one devotee. Only special pujas will be performed solely for the
        respective devotee.
        <p>&nbsp;</p>
        What prasadam will I receive after the puja is performed? The prasadam
        offered after the puja will vary based on the specific temple. However,
        please note that only non-perishable and dry prasadam will be delivered
        to the devotee.
        <p>&nbsp;</p>
        In case of further queries, whom can I contact? If you have any further
        queries or require assistance, please feel free to contact our customer
        care number at 8951269111. Alternatively, you can also reach out to us
        by email at info@devalayas.com or devalayas.in@gmail.com. Our dedicated
        support team will be ready to assist you.

        <p>&nbsp;</p>
        <div class="row"></div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
    let storedMobileNumber = localStorage.getItem("mobileNumber");
    if (
      storedMobileNumber != null &&
      storedMobileNumber != "null" &&
      storedMobileNumber != "+919080706050"
    ) {
      this.$store.dispatch("setsignInMbl", true);
    }
  },
};
</script>
