<template>
  <main style="background-color: white">
    <section class="pt-0"></section>
    <section class="pt-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div
              class="card bg-light p-0 pb-0"
              style="background-color: #f5f5f6"
            >
              <div
                class="card-body d-flex justify-content-between flex-wrap"
                style="text-align: left; background-color: #f5f5f6"
              >
                <div>
                  <!-- ss -->
                  <h1 class="h3 mt-2 mb-1">{{ temple.name }}</h1>
                  <p class="mb-2 mb-sm-0">
                    <i class="bi bi-geo-alt me-1 text-primary"></i>
                    {{ temple.address }}
                  </p>
                  <!--  -->
                  <carousel
                    :items-to-show="isMobileView ? 1 : 3"
                    autoplay="4000"
                    transition="500"
                    style="padding-bottom: 0%"
                  >
                    <slide v-for="(image, key) in temple.images" :key="key">
                      <div class="container">
                        <div class="row">
                          <div class="col-md">
                            <div style="align-items: baseline">
                              <img
                                style="
                                  max-width: 200px;
                                  max-height: fit-content;
                                  align-items: flex-start;
                                "
                                :src="image.image"
                                class="temple card-img"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </slide>
                  </carousel>
                  <!--  -->
                </div>
              </div>
              <!--  -->
              <div
                class="card-footer bg-transparent border-top py-0"
                style="background-color: white"
              >
                <ul
                  class="nav nav-tabs nav-bottom-line nav-responsive border-0"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a class="nav-link mb-0" data-bs-toggle="tab" href="#tab-1"
                      >About Temple</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link mb-0 active"
                      data-bs-toggle="tab"
                      href="#tab-2"
                      >Pooja Details</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pt-0">
      <div class="container">
        <div class="tab-content mb-0" id="tour-pills-tabContent">
          <div
            class="tab-pane fade"
            id="tab-1"
            role="tabpanel"
            aria-labelledby="tab-1"
          >
            <div class="row g-4 g-lg-5">
              <div class="col-lg-7 col-xl-8">
                <div
                  class="card bg-transparent mb-4"
                  style="border-color: black"
                >
                  <div
                    class="card-header bg-transparent border-bottom px-0 pt-0"
                  >
                    <h4 class="mb-0">Description</h4>
                  </div>
                  <div class="card-body px-0 pb-0">
                    <p class="mb-3">{{ temple.details }}</p>
                  </div>
                </div>
                <!-- Card END -->

                <!-- Card START -->
                <div class="card bg-transparent" style="border-color: black">
                  <!-- Card header -->
                  <div class="card-header bg-transparent border-bottom px-0">
                    <h4 class="mb-0">Image Gallery</h4>
                  </div>
                  <!-- Card body -->
                  <div class="card-body px-0 pb-0" style="border-color: black">
                    <div class="row g-4">
                      <div
                        class="col-md-4"
                        v-for="(image, key) in temple.images"
                        :key="key"
                      >
                        <a class="w-100 h-100">
                          <div class="card overflow-hidden">
                            <img
                              :src="image.image"
                              class="temple card-img"
                              alt=""
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xl-4">
                <div class="card shadow">
                  <div class="card-header border-bottom">
                    <h5 class="mb-0">Get Direction</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-borderless mb-0">
                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Taluk</span>
                        <span>{{ temple.taluk }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>District</span>
                        <span>{{ temple.district }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Area</span>
                        <span>{{ temple.area }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>City</span>
                        <span>{{ temple.city }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>State</span>
                        <span>{{ temple.state }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Pincode</span>
                        <span>{{ temple.pincode }}</span>
                      </li>

                      <li class="list-group-item py-0">
                        <hr class="my-1" />
                      </li>

                      <li
                        class="list-group-item d-flex justify-content-between"
                      >
                        <span>Website</span>
                        <span>{{ temple.website }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade show active"
            id="tab-2"
            role="tabpanel"
            aria-labelledby="tab-2"
          >
            <!--  -->
            <div
              class="bg-blur bg-white bg-opacity-10 border border-light border-opacity-25 rounded-3 mb-4"
            >
              <div class="row g-3 justify-content-center align-items-center">
                <div class="col-lg">
                  <div class="form-input-dropdown position-relative">
                    <input
                      v-model="search"
                      placeholder="Search for Pooja"
                      class="form-control form-control-lg me-1 ps-5"
                      @keyup.enter="getPoojas"
                      type="text"
                    />
                  </div>
                </div>

                <div class="col-lg-2 d-grid">
                  <a
                    class="btn btn-lg btn-primary mb-0"
                    href="#"
                    @click.prevent="getPoojas"
                    >Search</a
                  >
                </div>
              </div>
            </div>

            <!--  -->
            <div class="row g-4" style="justify-content: center">
              <!--  -->
              <div class="row g-4">
                <div
                  class="col-sm-6 col-lg-4 col-xl-3"
                  v-for="(pooja, key) in poojas"
                  :key="item"
                >
                  <a class="card shadow h-100">
                    <div class="card-body">
                      <ul
                        class="list-group list-group-borderless mb-0"
                        style="text-align: left"
                      >
                        <li class="list-group-item small pb-0">
                          <h5 style="color: #040404">🌸{{ pooja.name }}</h5>
                        </li>
                        <li class="list-group-item small pb-0">
                          <h6 style="margin: inherit; color: #ef5b0c">
                            Details:
                          </h6>
                          {{ pooja.details }}
                        </li>
                        <li class="list-group-item small pb-0">
                          <h6 style="margin: inherit; color: #ef5b0c">
                            Include's:
                          </h6>
                          <span class="me-2" v-html="pooja.included"></span>
                        </li>
                        <li class="list-group-item small pb-0">
                          <h6 style="margin: inherit; color: #ef5b0c">
                            Benefits:
                          </h6>
                          <span class="me-2" v-html="pooja.excluded"></span>
                        </li>
                        <li class="list-group-item small pb-0">
                          <h6 style="margin: inherit; color: #ef5b0c">Cost:</h6>
                          ₹ {{ pooja.original_cost }}/-
                        </li>
                      </ul>
                    </div>
                    <div class="card-footer">
                      <div>
                        <div
                          class="mt-2 mt-sm-0"
                          v-if="temple.pujari.length != 0"
                        >
                          <button
                            @click="bookPooja(pooja)"
                            class="button btn btn-sm btn-primary mb-0"
                          >
                            Participate
                            <i
                              class="fa fa-arrow-right pl-2 text-light my-auto"
                              style="position: relative; font-size: 16px"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                        <div class="mt-2 mt-sm-0" v-else>
                          <button
                            @click="requestPooja(pooja)"
                            style="width: 100%"
                            class="button btn btn-sm btn-primary mb-0"
                          >
                            <i class="bi bi-plus-lg"></i> Request for Pooja
                          </button>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <dialog id="payment" class="dialtag">
                <div v-if="request">
                  <div class="card shadow mb-4">
                    <div class="card-header border-bottom">
                      <div style="float: right">
                        <i
                          class="fas fa-times-circle"
                          style="font-size: 20px; color: rgb(255, 0, 0)"
                          v-on:click="close()"
                        ></i>
                      </div>
                      <h5 class="mb-0">Booking Details</h5>
                    </div>
                    <div class="card-body" style="text-align: left">
                      <div class="mb-2">
                        <label class="form-label"
                          >Pooja Name <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          v-model="booking.pooja_name"
                          class="form-control"
                          placeholder="Pooja Name"
                          disabled
                        />
                      </div>
                      <div class="mb-2">
                        <label class="form-label"
                          >Booking Date <span class="text-red">*</span></label
                        >
                        <VueDatePicker
                          placeholder="Select Booking Date"
                          v-model="booking.booking_date"
                          :class="{ 'is-invalid': error.booking_date != '' }"
                          auto-apply
                          :min-date="startDate"
                          :max-date="endDate"
                          :enable-time-picker="false"
                          :start-date="startDate"
                          focus-start-date
                          :disabled="!request"
                        ></VueDatePicker>
                        <small
                          ><span
                            v-if="error.booking_date != ''"
                            class="text-danger"
                            >{{ error.booking_date }}</span
                          ></small
                        >
                      </div>
                      <div class="mb-2">
                        <label class="form-label"
                          >Devotee Name <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          id="devotee_name"
                          v-model.trim="booking.name"
                          class="form-control"
                          placeholder="Devotee Name"
                          :class="{ 'is-invalid': error.name != '' }"
                          :disabled="!request"
                        />
                        <span
                          v-if="error.name != ''"
                          class="invalid-feedback"
                          >{{ error.name }}</span
                        >
                      </div>
                      <div class="mb-2">
                        <label class="form-label"
                          >Devotee Mobile Number
                          <span class="text-red">*</span></label
                        >
                        <input
                          type="text"
                          v-model="booking.mobile_no"
                          class="form-control"
                          placeholder="Devotee Mobile Number"
                          :class="{ 'is-invalid': error.mobile_no != '' }"
                          :disabled="!request"
                        />
                        <span
                          v-if="error.mobile_no != ''"
                          class="invalid-feedback"
                          >{{ error.mobile_no }}</span
                        >
                      </div>
                      <div class="mb-2">
                        <label class="form-label">Rashi</label>
                        <textarea
                          v-model="booking.rashi"
                          class="form-control"
                          placeholder="Rashi"
                          :disabled="!request"
                        ></textarea>
                      </div>
                      <div class="mb-2">
                        <label class="form-label">Nakshatra</label>
                        <textarea
                          v-model="booking.nakshatra"
                          class="form-control"
                          placeholder="Nakshatra"
                          :disabled="!request"
                        ></textarea>
                      </div>
                      <div class="mb-2">
                        <label class="form-label">Gotra</label>
                        <textarea
                          v-model="booking.gotra"
                          class="form-control"
                          placeholder="Gotra"
                          :disabled="!request"
                        ></textarea>
                      </div>
                      <div class="mb-2">
                        <label class="form-label">Sankalpa</label>
                        <textarea
                          v-model="booking.comment"
                          class="form-control"
                          placeholder="Sankalpa"
                          :disabled="!request"
                        ></textarea>
                      </div>
                      <div v-show="prasad_delivery">
                        <div class="mb-2">
                          <label for="prasadam">
                            <input
                              id="prasadam"
                              v-model="booking.prasadam"
                              type="checkbox"
                              style="vertical-align: middle"
                              :disabled="!request"
                            />
                            Do you want Prasadam ?</label
                          >
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label"
                            >Street Address 1<span class="text-red"
                              >*</span
                            ></label
                          >
                          <input
                            type="text"
                            id="address"
                            v-model="booking.prasadam_address.street_address_1"
                            class="form-control"
                            placeholder="Address"
                            :class="{ 'is-invalid': error.address_1 != '' }"
                          />
                          <span
                            v-if="error.address_1 != ''"
                            class="invalid-feedback"
                            >{{ error.address_1 }}</span
                          >
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label">Street Address 2</label>
                          <input
                            type="text"
                            id="address"
                            v-model="booking.prasadam_address.street_address_2"
                            class="form-control"
                            placeholder="Address"
                          />
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label"
                            >Area<span class="text-red">*</span></label
                          >
                          <input
                            type="text"
                            id="area"
                            v-model="booking.prasadam_address.area"
                            class="form-control"
                            placeholder="Area"
                            :class="{ 'is-invalid': error.area != '' }"
                          />
                          <span
                            v-if="error.area != ''"
                            class="invalid-feedback"
                            >{{ error.area }}</span
                          >
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label"
                            >City <span class="text-red">*</span></label
                          >
                          <input
                            type="text"
                            id="city"
                            v-model="booking.prasadam_address.city"
                            class="form-control"
                            placeholder="City"
                            :class="{ 'is-invalid': error.city != '' }"
                          />
                          <span
                            v-if="error.city != ''"
                            class="invalid-feedback"
                            >{{ error.city }}</span
                          >
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label"
                            >State <span class="text-red">*</span></label
                          >
                          <input
                            type="text"
                            id="state"
                            v-model="booking.prasadam_address.state"
                            class="form-control"
                            placeholder="State"
                            :class="{ 'is-invalid': error.state != '' }"
                          />
                          <span
                            v-if="error.state != ''"
                            class="invalid-feedback"
                            >{{ error.state }}</span
                          >
                        </div>
                        <div class="mb-2" v-show="this.booking.prasadam">
                          <label class="form-label"
                            >Pincode <span class="text-red">*</span></label
                          >
                          <input
                            type="text"
                            v-model="booking.prasadam_address.pincode"
                            class="form-control"
                            placeholder="Pincode"
                            :class="{ 'is-invalid': error.pincode != '' }"
                          />
                          <span
                            v-if="error.pincode != ''"
                            class="invalid-feedback"
                            >{{ error.pincode }}</span
                          >
                        </div>
                      </div>
                      <div class="mb-0" v-if="request">
                        <button
                          @click="confirmBooking()"
                          class="btn btn-primary w-100"
                        >
                          Confirm Booking
                        </button>
                        <label class="note"
                          >Note: Video availability is subject to temple
                          consent</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="response">
                  <div class="card-header border-bottom">
                    <div style="float: right">
                      <i
                        class="fas fa-times-circle"
                        style="font-size: 20px; color: rgb(255, 0, 0)"
                        v-on:click="close()"
                      ></i>
                    </div>
                    <h5 class="mb-0">Order Details</h5>
                  </div>
                  <div class="card-body">
                    <ul class="list-group list-group-borderless mb-3">
                      <li
                        class="list-group-item d-flex justify-content-between py-2"
                      >
                        <span class="h6 fw-light mb-0">Pooja Cost</span>
                        <span class="h6 fw-light mb-0"
                          >₹{{ payment?.payment_data?.original_cost }}</span
                        >
                      </li>
                      <div v-show="this.booking.prasadam">
                        <li
                          class="list-group-item d-flex justify-content-between py-2"
                        >
                          <span class="h6 fw-light mb-0"
                            >Prasadam Delivery</span
                          >
                          <span class="h6 fw-light mb-0"
                            >₹{{ payment?.payment_data?.delivery_charge }}</span
                          >
                        </li>
                      </div>
                      <li
                        class="list-group-item d-flex justify-content-between py-2"
                      >
                        <span class="h6 fw-light mb-0">Convenience Fee</span>
                        <span class="h6 fw-light mb-0"
                          >₹{{ payment?.payment_data?.convenience_fee }}</span
                        >
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between py-2"
                      >
                        <span class="h6 fw-light mb-0">Tax</span>
                        <span class="h6 fw-light mb-0"
                          >₹{{ payment?.payment_data?.total_tax }}</span
                        >
                      </li>

                      <li class="list-group-item py-2">
                        <hr class="my-0" />
                      </li>
                      <li
                        class="list-group-item d-flex justify-content-between pb-0"
                      >
                        <div>
                          <h5 class="fw-normal mb-0">Total</h5>
                          <small>Inc. of all taxes</small>
                        </div>
                        <span class="h5 fw-normal mb-0"
                          >₹{{ payment?.payment_data?.final_total }}</span
                        >
                      </li>
                    </ul>
                    <button
                      @click="razorpayPayment"
                      class="btn btn-primary w-100 mb-0"
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </dialog>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import "firebase/database";
import "firebase/compat/auth";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "Temple",
  components: {
    VueDatePicker,
    Carousel,
    Slide,
  },

  data() {
    return {
      isMobileView: false,
      phoneNumber: "",
      verificationCode: "",
      verificationId: "",
      displayName: "",
      mobileNo: "",
      mobileNoError: "",
      search: "",
      rz_key: "",
      temple: {},
      poojas: {},
      booking: {
        pooja_id: "",
        pooja_name: "",
        pooja_price: 0,
        prasad_delivery: false,
        booking_date: "",
        name: "",
        mobile_no: "",
        comment: "",
        nakshatra: "",
        kula: "",
        gotra: "",
        rashi: "",
        nakshatra: "",
        prasadam: false,
        prasadam_address: {
          street_address_1: "",
          street_address_2: "",
          area: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      payment: {},
      error: {
        booking_date: "",
        name: "",
        mobile_no: "",
        address_1: "",
        address_2: "",
        area: "",
        city: "",
        state: "",
        pincode: "",
      },
      request: false,
      response: false,
      signGoogle: true,
      updateProfile: false,

      booking_date: {
        startDate: "",
        endDate: "",
      },
      ref: null,
      order_id: "",
      googleEmail: "-",
      user: {
        mobile_number: "",
        login_token: "123",
        app_version: "1",
        device_model: "Browser",
        user_type: "Devotee",
      },
      token: "73bfce87b51fae1532245c5cd75b6cb03e4641d5",
      message: [],
      errors: [],
      setmobilelogin: false,
      newUser: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    let vm = this;
    next((vm) => {
      vm.$store
        .dispatch("get", { uri: "devotee/temple/" + to.params.id })
        .then((response) => {
          vm.temple = response.data;
          vm.getPoojas(to.params.id);
        })
        .catch((errors) => {
          console.log(errors);
        });
    });
  },

  mounted() {
    window.scrollTo(0, 0);
    this.isMobileView = window.innerWidth <= 768;
    window.addEventListener("resize", this.handleResize);
    let storedMobileNumber = localStorage.getItem("mobileNumber");
    if (
      storedMobileNumber != null &&
      storedMobileNumber != "null" &&
      storedMobileNumber != "+919080706050"
    ) {
      this.$store.dispatch("setsignInMbl", true);
    }
  },

  beforeDestroy() {
    // Remove the resize event listener to avoid memory leaks
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      // Update isMobileView based on the window width
      this.isMobileView = window.innerWidth <= 768;
    },

    closeModal() {
      document.getElementById("dialog").close();
    },
    close() {
      document.getElementById("payment").close();
    },
    getPoojas() {
      let vm = this;
      let loader = this.$loading.show();
      this.$store
        .dispatch("get", {
          uri:
            "devotee/pooja/?temple=" + vm.temple.id + "&search=" + this.search,
        })
        .then((response) => {
          loader.hide();
          vm.poojas = response.data.results;
          // console.log(vm.poojas);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    bookPooja(pooja) {
      let vm = this;
      let storedMobileNumber = localStorage.getItem("mobileNumber");
      if (
        storedMobileNumber != null &&
        storedMobileNumber != "null" &&
        storedMobileNumber != "+919080706050"
      ) {
        this.booking.mobile_no = storedMobileNumber;
        this.$store.dispatch("setsignInMbl", true);
      } else {
        this.booking.mobile_no = "";
      }

      if (this.$store.getters.signInMbl == false) {
        document.getElementById("dialog").showModal();
      }
      if (this.$store.getters.signInMbl == true) {
        vm.response = false;
        document.getElementById("payment").showModal();
        this.$store
          .dispatch("get", { uri: "devotee/constants/" })
          .then((response) => {
            let pooja_availability = response.data.pooja_availability;
            let pooja_booking_min_csc_estore =
              response.data.pooja_booking_min_csc_estore;
            vm.booking_date.startDate = moment()
              .add(pooja_booking_min_csc_estore, "d")
              .toDate();
            vm.booking_date.endDate = moment()
              .add(pooja_availability + pooja_booking_min_csc_estore, "d")
              .toDate();
            vm.booking.pooja_id = pooja.id;
            vm.booking.pooja_name = pooja.name;
            vm.booking.pooja_price = pooja.final_total;
            vm.booking.prasad_delivery = pooja.prasad_delivery;
            vm.request = true;
          })
          .catch((errors) => {
            loader.hide();
            console.log(errors);
          });
      }
    },
    requestPooja(pooja) {
      let vm = this;
      let loader = this.$loading.show();
      vm.$store
        .dispatch("get", { uri: "devotee/pooja/" + pooja.id + "/request" })
        .then((response) => {
          loader.hide();
          alert("Pooja request has been successfully submited.");
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },

    confirmBooking() {
      let vm = this;
      vm.response = false;
      let address_status = true;
      vm.error.booking_date = "";
      vm.error.name = "";
      vm.error.mobile_no = "";
      vm.error.address_1 = "";
      vm.error.area = "";
      vm.error.pincode = "";
      vm.error.city = "";
      vm.error.state = "";
      const regName_1 = /^[a-zA-Z]+ [a-zA-Z]+$/;
      const regName_2 = /^[a-zA-Z]+$/;
      const pin_reg_ex = /^\d{4,6}\b/g;
      const regex = /^\+[0-9]{11,12}$/;
      if (vm.booking.booking_date == "") {
        vm.error.booking_date = "The booking date is required";
      }
      if (vm.booking.name == "") {
        vm.error.name = "The devotee name is required";
      }
      if (
        !regName_1.test(this.booking.name) &&
        !regName_2.test(this.booking.name)
      ) {
        vm.error.name = "Please enter a valid full name";
      }
      if (vm.booking.mobile_no == "") {
        vm.error.mobile_no = "The devotee mobile number is required";
      }
      if (!regex.test(this.booking.mobile_no)) {
        vm.error.mobile_no =
          "Enter devotee number in the format '+919876543210'.";
      }
      if (
        vm.booking.prasadam &&
        vm.booking.prasadam_address.street_address_1 == ""
      ) {
        vm.error.address_1 = "Address is required for prasadam delivery";
        address_status = false;
      }
      if (vm.booking.prasadam && vm.booking.prasadam_address.pincode == "") {
        vm.error.pincode = "Pincode is required";
        address_status = false;
      }
      if (
        vm.booking.prasadam &&
        vm.booking.prasadam_address.pincode.length != 6
      ) {
        vm.error.pincode = "Pincode should be of 6 digits";
        if (!pin_reg_ex.test(vm.booking.prasadam_address.pincode)) {
          vm.error.pincode = "Please enter a valid pincode!";
        }
        address_status = false;
      }
      if (vm.booking.prasadam && vm.booking.prasadam_address.area == "") {
        vm.error.area = "Area is required";
        address_status = false;
      }
      if (vm.booking.prasadam && vm.booking.prasadam_address.city == "") {
        vm.error.city = "City is required";
        address_status = false;
      }
      if (vm.booking.prasadam && vm.booking.prasadam_address.state == "") {
        vm.error.state = "State is required";
        address_status = false;
      }
      if (
        vm.error.name == "" &&
        vm.error.mobile_no == "" &&
        vm.error.booking_date == "" &&
        address_status
      ) {
        let payload = {
          uri: "devotee/pooja_request/",
          data: {
            pooja: vm.booking.pooja_id,
            pooja_date: moment(String(vm.booking.booking_date)).format(
              "YYYY-MM-DD"
            ),
            name: vm.booking.name,
            devotee_number: vm.booking.mobile_no,
            is_prasadam_delivery: this.booking.prasadam,
            prasadam_address: {
              street_address_1: this.booking.prasadam_address.street_address_1,
              street_address_2: this.booking.prasadam_address.street_address_2,
              area: this.booking.prasadam_address.area,
              city: this.booking.prasadam_address.city,
              state: this.booking.prasadam_address.state,
              pincode: this.booking.prasadam_address.pincode,
            },
            family_member: [
              {
                id: null,
                name: vm.booking.name,
                father_name: "",
                kula: vm.booking.kula,
                gotra: vm.booking.gotra,
                rashi: vm.booking.rashi,
                nakshatra: vm.booking.nakshatra,
                caste: "",
                subcaste: "",
                age: "",
                save_for_future: false,
                date_of_birth: null,
                place_of_birth: null,
                time_of_birth: null,
              },
            ],
            comment:
              vm.booking.comment +
              "( Nakshatra :" +
              vm.booking.nakshatra +
              ")( Kula :" +
              vm.booking.kula +
              ")( Gotra :" +
              vm.booking.gotra +
              ")( Rashi : " +
              vm.booking.rashi +
              ")",
            booked_by: "CSC",
          },
        };

        if (!this.booking.prasadam) {
          payload = {
            uri: "devotee/pooja_request/",
            data: {
              pooja: vm.booking.pooja_id,
              pooja_date: moment(String(vm.booking.booking_date)).format(
                "YYYY-MM-DD"
              ),
              name: vm.booking.name,
              devotee_number: vm.booking.mobile_no,
              is_prasadam_delivery: this.booking.prasadam,
              family_member: [
                {
                  id: null,
                  name: vm.booking.name,
                  father_name: "",
                  kula: vm.booking.kula,
                  gotra: vm.booking.gotra,
                  rashi: vm.booking.rashi,
                  nakshatra: vm.booking.nakshatra,
                  caste: "",
                  subcaste: "",
                  age: "",
                  save_for_future: false,
                  date_of_birth: null,
                  place_of_birth: null,
                  time_of_birth: null,
                },
              ],
              comment:
                vm.booking.comment +
                "( Nakshatra :" +
                vm.booking.nakshatra +
                ")( Kula :" +
                vm.booking.kula +
                ")( Gotra :" +
                vm.booking.gotra +
                ")( Rashi : " +
                vm.booking.rashi +
                ")",
              booked_by: "CSC",
            },
          };
        }

        let loader = this.$loading.show();
        this.$store
          .dispatch("post", payload)
          .then((response) => {
            loader.hide();
            vm.payment = response.data;
            vm.request = false;
            vm.response = true;
            vm.getRZKey();
            vm.order_id = response.data.order_id;
          })
          .catch((errors) => {
            loader.hide();
            // alert(errors.response.data.errors[0].message[0]);
          });
      }
    },
    getRZKey() {
      let vm = this;
      this.$store
        .dispatch("get", { uri: "devotee/payment_key/" })
        .then((response) => {
          vm.rz_key = response.data.key;
          vm.scrollToOrderDetails();
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    razorpayPayment() {
      let vm = this;
      document.getElementById("payment").close();
      var options = {
        key: vm.rz_key,
        amount: this.payment.payment_data.final_total * 100,
        currency: "INR",
        name: "Devalaya",
        description: "Payment towards Pooja",
        image:
          "https://cdn.shopify.com/s/files/1/0735/5895/0166/files/unnamed_copy_ac3ece77-8a3a-44b7-b0f2-820c39455044.jpg?v=1679241399&width=500",
        order_id: this.payment.payment_order_id,
        handler: function (response) {
          vm.placeOrder(response);
        },
        prefill: {
          name: this.devotee_name,
          email: "",
          contact: this.devotee_mobile,
        },
        notes: {
          address: "Devalaya",
        },
        theme: {
          color: "#df3002",
        },
      };
      var rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        // alert(JSON.stringify(response));
      });
      rzp1.open();
    },

    placeOrder(rz_response) {
      let vm = this;
      let data = {
        razorpay_response: rz_response,
        request_id: vm.payment.id,
      };
      let loader = this.$loading.show();
      this.$store
        .dispatch("post", { uri: "devotee/pooja_request/payment/", data: data })
        .then((response) => {
          loader.hide();
          vm.$router.push({
            name: "order",
            params: {
              payment_id: rz_response.razorpay_payment_id,
              order_id: vm.order_id,
            },
          });
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
    scrollToOrderDetails() {
      this.$refs.orderDetails.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style>
.text-red {
  color: red;
  font-weight: bold;
}
.note {
  display: inline-block;
  /* size: a3; */
  color: rgb(108 108 108);
  /* width: auto; */
  font-size: 13px;
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    cursor: not-allowed;
  }
  .body {
    text-align: center;
    padding-top: 2rem;
  }
}
.pt-0 {
  background-color: #fde5d1;
}
</style>
