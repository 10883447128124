<template>
    <main>
        <div class="container" style="text-align: left;">
          <br>
          <br>
          <img class="mb-4" src="https://www.devalayas.in/assets/images/tc.png" alt="" />
          <br>
          <p><h5>Terms and Conditions  :</h5></p>
          <p class="text-justify">This document is an electronic record in terms of the Indian Contract Act, 1872, Information Technology Act, 2000, the rules made there under, and the amended provisions pertaining to electronic records in various other statutes, as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system it does not require any physical or digital signatures.
            This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, which requires publishing the rules and regulations, privacy policy and Terms of Service for the access or usage of https://devalayas.com ("Website") and DEVALAYA ("App").
            The Website and App are owned and operated by Anek Technologies Private Limited with its place of business at SP OFFICE ROAD, NEAR KOLHAPUR CIRCLE, BELGAUM, KARNATAKA 590010, INDIA.
            Please read these Terms of Service fully and carefully before using the Website or App and the services, features, content or applications maintained by Anek Technologies Private Limited (“devalaya” “we”, “us” or “our”).&nbsp;</p>
          
          <p class="text-justify">IF YOU DO NOT AGREE TO BE BOUND BY ALL CONDITIONS/CLAUSES CAPTURED HERE UNDER, PLEASE DO NOT USE THE WEBSITE OR APP.&nbsp;</p>
          <p class="text-justify"><strong>&nbsp;1. Definition:&nbsp;</strong></p>
          <p class="text-justify">&nbsp;1.1 “Content” shall include, without limitation, information, data, text, photographs, videos, shoots, audio clips, written posts and comments, software, scripts, graphics, and interactive features generated, provided, or otherwise made accessible on or through the Website and App. Such Content shall either be owned by Devalaya or owned by various temple or guru partners along with rights granted to Devalaya to publish such content on the Website and App and; made available to the users through Website/App.&nbsp;</p>
          <p class="text-justify">&nbsp;1.2 “Services” shall mean the Content (defined hereunder), the video streaming of the Content, Website/App, and any other features, functionalities, applications, materials, prasad service, puja service, donation service or other Services (defined hereunder) offered from time to time on the App or Website in connection with any of the above, however accessed, are referred to collectively as the “Services” or the “App Services”)&nbsp;</p>
          <p class="text-justify">&nbsp;1.3 “Terms”, “Terms and conditions”, “Policy”, “T&amp;C”, “Terms of Service”, “Terms of Use” shall mean the entire Agreement and all parallel policies that You agree to in mutual agreement by using our Website/App; and&nbsp;</p>
          <p class="text-justify">&nbsp;1.4 ‘You’ or ‘Your’ or ‘User’ refers to the user or viewer of the Website/App. User is anyone who is visiting, accessing, browsing, or taking Services through the Website/App.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify">&nbsp;<h5>2. Acceptance of Terms and Conditions:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;By registering for and/or using the Services in any manner, including visiting or browsing the Website/App, You agree to these Terms and conditions, the Privacy Policy and all other operating rules, policies and procedures that may be published from time to time on the Website/App by us, each of which is incorporated by reference and each of which may be updated from time to time without notice given to You.
            Certain Services may be subject to additional Terms and conditions specified by us from time to time. Your use of such Services is subject to those additional Terms and Conditions, which are incorporated into these Terms of Service by this reference. You are requested to update yourself to these new/additional terms from time to time.
            These Terms of Service apply to all Users of the Services, including, without limitation, information, and other materials or Services, registered or otherwise.&nbsp;</p>
          <p class="text-justify">&nbsp;<h5>Eligibility:</h5> You represent and warrant that You are at least 18 (eighteen) years of age. Devalaya does not accept agreements and payments from persons under the legal age of 18 years. By submitting your account application, You confirm that You are over 18 years of age or Your parent or legal guardian has agreed to accept this Agreement on your behalf. We may, in our sole discretion, refuse to offer the Services to any person or entity and change its eligibility criteria at any time. You are solely responsible for ensuring that these Terms of Service are in compliance with all laws, rules and regulations applicable to You and the right to access the Services is revoked where these Terms of Service or use of the Services is prohibited or to the extent offering sale or provision of the Services conflicts with any applicable law, rule or regulation. Further, the Services are offered only for Your use, and not for the use or benefit of any third party.</p>
          <p class="text-justify">&nbsp; <h5>Registration:</h5> To sign up for the Services, You must register for an account and create a profile on the Services (an “Account”). Any information You provide to us for the purposes of registering and setting up your account shall be considered as accurate, complete and updated. You are solely responsible for the activity that occurs on your account and for maintaining the security with the Website and any information You input to the Website or App. You must provide accurate and complete information and keep Your Account information updated. You shall not: (i) select or use as a username a name of another person with the intent to impersonate that person; (ii) use as a username a name subject to any rights of a person other than You without appropriate authorization; or (iii) use as a username, a name that is otherwise offensive, vulgar or obscene. You are solely responsible for the activity that occurs on Your Account, and for keeping Your Account password secure. You may never use another person’s user account or registration information for the Services without permission. You must notify us immediately of any change in Your eligibility to use the Services (including any changes to or revocation of any licenses from state authorities), breach of security or unauthorized use of Your Account. You should never publish, distribute or post login information for Your Account. You shall have the ability to delete Your Account, either directly or through a request made to one of our employees or affiliates. By registering Your Account through a third party service, such as Facebook Connect, You agree to be bound by all applicable policies of such third party service and will act in accordance with them.&nbsp;</p>
<p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>3. The use of the Website/App is subject to the following conditions:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;1.	The Content hosted on the Website/App is for personal viewing purposes. Devalaya may at its sole discretion add, edit, modify or update the content at its sole discretion. Your continued use of the services following the posting of any changes to these terms constitute unconditional acceptance of such changes.&nbsp;</p>
          <p class="text-justify">&nbsp;2.	The booking of forthcoming Puja through Devalaya app shall be solely done in manner provided on the app. The booking of any puja can only be done within the timeframe of one day to 15 days in advance from the date of performance of the puja as notified on the website/app of Devalaya. Under no circumstances, can this be considered as a mandatory right to perform the puja, since we exclusively reserve the right to suspend, postpone, and terminate on occurrence of any event beyond our control. However, if the puja, so scheduled to be performed, if not performed due to reasons exclusively attributable to us, then Devalaya will refund the amount so paid towards that specific booking of puja within 7 working days of the bank.&nbsp;</p>
          <p class="text-justify">&nbsp;3.	After performance of the puja, the video will be made available within 3 working days of the date of performance of puja. However, Devalaya does not in any way guarantee the quality of the video. It shall be solely at the discretion and convenience of the panditji performing the puja/service.&nbsp;</p>
          <p class="text-justify">&nbsp;4.	The duration/length of video recorded with respect to the puja/service/event shall be maximum of a length of 3 minutes.&nbsp;</p>
          <p class="text-justify">&nbsp;5.	We do not provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the content and materials found or offered on the Website/App for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.&nbsp;</p>
          <p class="text-justify">&nbsp;6.	Your use of any information or materials on the Website/App is entirely at Your own risk, for which we shall not be liable. It shall be Your responsibility to ensure that any Services or information available through the Website/App meet Your specific requirements.&nbsp;</p>
          <p class="text-justify">&nbsp;7.	The Website/App contains material which is owned by various temple or guru partners and rights of use granted to Us.&nbsp;</p>
          <p class="text-justify">&nbsp;8.	All trademarks reproduced in the Website/App, if any, which are not the property of, or licensed to us, are acknowledged on the Website/App.&nbsp;</p>
          <p class="text-justify">&nbsp;9.	Unauthorized use of the Website/App may result in suspension of user account, a claim for damages and/or be a criminal offence.&nbsp;</p>
          <p class="text-justify">&nbsp;10.	From time to time the Website/App may also include links to other websites. These links are provided for Your convenience to provide further information. They do not signify that we endorse the Website(s). We have no responsibility for the Content of the linked Website(s).&nbsp;</p>
          <p class="text-justify">&nbsp;11.	You are prohibited to create a link to this Website/App from another Website.&nbsp;</p>
          <p class="text-justify">&nbsp;12.	You may use a Virtual Reality headset for watching the Content, which includes 360 degree videos and interactivity, however, You shall defend, indemnify, and hold us harmless, our affiliates and each of our employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to the use of such VR headsets.&nbsp;</p>
          <p class="text-justify">&nbsp;13.	You agree to make use of the services in accordance with these terms and expressly agree not to use any other medium other than what is prescribed by us.&nbsp;</p>
          <p class="text-justify">&nbsp;14.	Using our Services does not give You ownership of any intellectual property rights in our Services or the content You access.&nbsp;</p>
          <p class="text-justify">&nbsp;15.	These terms do not grant You the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services. In connection with your use of the Services, we may send You service announcements, administrative messages, and other information.&nbsp;</p>
          <p class="text-justify">16.	Our services are available on mobile platform in the form of the App. Do not use such Services in a way that distracts You and prevents You from obeying traffic or safety laws.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;4. Copyright notice:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;1.	The contents of the Website and App, information, text, graphics, images, logos, button icons, brand names, software code, design, and the collection, arrangement and assembly of content on the Website (collectively, "Our Content"), are our property and are protected under copyright, trademark and other laws. You shall not modify Our Content or reproduce, display, publicly perform, distribute, or otherwise use Our Content in any way for any public or commercial purpose or for personal gain.&nbsp;</p>
          <p class="text-justify">&nbsp;2.	You shall not access the Services for purposes of monitoring their availability, performance or functionality, or for any other benchmarking or competitive purposes.&nbsp;</p>
          <p class="text-justify">&nbsp;3.	You are prohibited from downloading any content from the Website/ App or making the content available offline. Any redistribution or reproduction of part or all of the contents in any form is prohibited. If such actions on your behalf are experienced from our end, then Devalaya shall hold you responsible for all the costs and consequences of such actions, irrespective of the fact whether your actions cause any damage to the website/app or not.&nbsp;</p>
          <p class="text-justify">&nbsp;4.	You may not, except with our express written permission, distribute or commercially exploit the Content nor may You transmit it or store it in any other Website or other form of electronic retrieval system.&nbsp;</p>
          <p class="text-justify">&nbsp;5.	Devalaya, its affiliates and its licensors/licensees, as the case may be shall have, and shall continue to retain, all title, rights and interests in the Website/App and the Content.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;5. Third Party Services:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;The Services may permit You to link to other websites, services or resources on the Internet, and other websites, when You access third party resources on the Internet, You do so at Your own risk. These other resources are not under our control, and You acknowledge that we are not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such websites or resources. The inclusion of any such link does not imply our endorsement or any association between us and their operators. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such Website or resource. Any transaction, dealings or communication otherwise that You may have with such third parties are at your own risk and we make no warranties, express or implied regarding the quality or suitability of the services or products of such third-party vendors.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;6. Payment Method and Refund policy:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;1.	The terms of Your payment will be based on Your Payment Method and may be determined by agreements between You and the financial institution, credit card issuer or other provider of Your chosen Payment Method. If we, through the Payment Processor, do not receive payment from You, You agree to pay all amounts due on Your Billing Account against pooja and/or prasadam fees upon demand. Any order placed through the Website/App for pooja services and/or prasadam cannot be cancelled and any payment once made will not be refunded unless the services that are requested (pooja services, prasadam etc ) cannot be offered from the devalaya side(on devalaya terms). The date selected for the pooja(s) or offering(s) cannot be changed after making the payment. However, if the temple due to reasons best known to them, do decide to cancel a Puja or an event scheduled by You, an alternative date will be provided for the same. The processing fees or transaction costs, if any, should be borne by the card holder and may be charged extra with the poojas and offerings rate.&nbsp;</p>
          <p class="text-justify">&nbsp;2.	Security: Please do not share Your card details, login details etc. with our employees or officers or any third-parties to prevent/avoid any fraudulent use of Your credit/ debit and internet banking accounts. Any card information provided on the Website/App is completely secured. We take reasonable measures to ensure that every profiles’ identity and payment details are highly protected.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;
          7. Rules and Conduct:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h5></p>
          <p class="text-justify">As a condition of use, You promise not to use the Service for any purpose that is prohibited by these terms, by law, derogatory to any practice or belief, or other rules or policies implemented by us from time to time. The Service (including, without limitation, any Content) is provided only for Your own personal, non- commercial use.
            Furthermore, You shall not (directly or indirectly): (i) interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service; (ii) bypass any measures we may use to prevent or restrict access to the Service (or parts thereof); (iii) decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the limited extent applicable laws specifically prohibit such restriction; (iv) modify, translate, or otherwise create derivative works of any part of the Service; or (v) copy, rent, lease, distribute, or otherwise transfer any or all of the rights that You receive hereunder.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;
          8. Typographical Errors:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;In the event, that an entry listed on the Services is mistakenly listed at an incorrect price, we reserve the right to refuse or cancel any orders placed for the same listed at the incorrect price. We reserve the right to refuse or cancel such orders whether or not the order has been confirmed and Your credit card charged. If Your credit card has already been charged for the order and Your order is cancelled, refunds shall be processed through the same mode of payment.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;9. Termination:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;We may terminate Your access to all or any part of the Services at any time, with or without cause and notice, effective immediately, which may result in the forfeiture and destruction of all information associated with Your membership. If You wish to terminate Your Account, You may do so by following the instructions on the Website/App or through the Services. Any fees paid hereunder are non-refundable. All provisions of these Terms of Service which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;10. Warranty Disclaimer:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;1.	We have no special relationship with or fiduciary duty to You. You acknowledge that we have no duty to take any action regarding:&nbsp;</p>
          <p class="text-justify">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1.	which users gain access to the Services;&nbsp;</p>
<p class="text-justify">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2.	what Content You access via the Services; or&nbsp;</p>
          <p class="text-justify">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3.	how You may interpret or use the Content.&nbsp;</p>
          <p class="text-justify">&nbsp;2.	You release us from all liability for You having acquired or not acquired Content through the Services. We make no representations concerning any Content contained in or accessed through the Services, and we will not be responsible or liable for the accuracy, copyright compliance, or legality of material or Content contained in or accessed through the Services.&nbsp;</p>
          <p class="text-justify">&nbsp;3.	The Services and Content are provided “as is”, “as available” and without warranty of any kind, express or implied, including, but not limited to, the implied warranties of title, non- infringement, merchantability and fitness for a particular purpose and any warranties implied by any course of performance or usage of trade, all of which are expressly disclaimed. We and our directors, employees, agents, suppliers, partners and Content providers do not warrant that: (i) the Services will be secure or available at any particular time or location; (ii) any defects or errors will be corrected; (iii) any Content or software available at or through the Services is free of viruses or other harmful components; or (iv) the results of using the Services will meet Your requirements. Your use of the Services is solely at Your own risk.&nbsp;</p>
          <p class="text-justify">&nbsp;4.	The use of the Website/ App may lead to overheating of certain devices such as mobile phones, tablets, laptops or any other devices used as a medium to take benefit of Devalaya services. It is suggested that the overheated mobile device must be allowed to cool down before further use. Further, it is hereby clarified that Devalaya shall in no event be held liable for any damages caused to the mobile device as a result of overheating.&nbsp;</p>
          <p class="text-justify">&nbsp;5.	System Uptime: We will use commercially reasonable efforts to make the Website/App generally accessible to Users via the internet, 24 (twenty-four) hours a day, 7 (seven) days a week unless the same is not possible due to: (a) scheduled network, hardware or service maintenance; (b) a failure of the internet and/or the public switched telephone network, or (c) the occurrence of any event that is beyond our reasonable control (collectively, the "Excusable Downtime"). We will use all commercially reasonable efforts to make the Website/App available at least 95% (ninety-five per cent) of the time during each month, excluding Excusable Downtime ("Uptime Commitment"). We shall maintain commercially reasonable and industry standard safeguards for data integrity of transmissions as received by or otherwise transmitted to us from Users or to or from the Website/App, provided that, we shall not be responsible or have liability for data integrity beyond the original condition as received by or otherwise transmitted to us. We shall take commercially reasonable actions to protect against threats that deny Service and thus reduce availability of the Website/App or control by providing mechanisms to protect the transmission facilities, switching components, network management systems and other essential Service provider facilities from unauthorized denial-of service attacks, insider attacks, unauthorized or unexpected user actions, unauthorized intrusions, and other perceived threats.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;11. Indemnification:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;You shall defend, indemnify, and hold us harmless, our affiliates and each of our and their respective employees, contractors, directors, suppliers and representatives from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to Your use or misuse of, or access to, the Services, Content, or use of VR headsets or any security/data breach or hacking of the system/computer (including IT and operational failures) or otherwise from Your User Content, violation of these Terms and Conditions, or infringement by You, or any third party using Your Account or identity in the Services, of any intellectual property or other right of any person or entity. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by You, in which event You will assist and cooperate with us in asserting any available defences.</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;12. Limitation of Liability:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;In no event shall we, nor our directors, employees, agents, partners, suppliers or content providers, be liable under contract, tort, strict liability, negligence or any other legal or equitable theory with respect to the Services (i) for any lost profits, data loss, cost of procurement of substitute goods or Services, or special, indirect, incidental, punitive, compensatory or consequential damages of any kind whatsoever, substitute goods or Services (however arising), (ii) for any bugs, viruses, trojan horses, or the like (regardless of the source of origination), or (iii) for any direct damages in excess of (in the aggregate) of the greater of fees paid to us for the particular Services during the immediately previous 1 (one) month period or total fee paid in case of pay-per view subscription (as the case may be).&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;13. Governing Law and Jurisdiction:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;These Terms of Service shall be governed by and construed in accordance with the laws of India. You agree that any dispute arising from or relating to the subject matter of these Terms of Service shall be governed by the exclusive jurisdiction of courts situated at Bangalore, Karnataka, India.
            13.A Dispute Resolution.
            "Any dispute or difference whatsoever arising between the parties out of or relating to the construction, meaning, scope, operation or effect of these Terms and Conditions or the validity or the breach thereof shall be settled by arbitration in accordance with the Rules of Arbitration of the Indian Council of Arbitration and the award made in pursuance thereof shall be binding on the parties. Irrespective of whether the person deriving benefits from the services of this App/ Website is a resident of India or not, it is hereby agreed that, the place of arbitration shall be Belagavi, Karnataka, India.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
<p class="text-justify"><h5>&nbsp;14. Modification:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;We reserve the right, in our sole discretion, to modify or replace any of these Terms of Service, or change, suspend, or discontinue the Services (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the Website/App or by sending You notice through the Services, via e-mail or by another appropriate means of electronic communication. We may also impose limits on certain features and Services or restrict Your access to parts or all of the Services without notice or liability. While we will provide timely notice of modifications, it is also Your responsibility to check these Terms of Service periodically for changes. Your continued use of the Services following notification of any changes to these Terms of Service constitutes acceptance of those changes.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;15. Entire Agreement and Severability:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;These Terms of Service and Privacy Policy constitute entire agreement between You and us with respect to the Services, including use of the Website/App, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between You and us with respect to the Services. If any provision of these Terms of Service is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that these Terms of Service will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;16. Force Majeure:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;We shall not be liable for any failure to perform our obligations hereunder where such failure results from any cause beyond our reasonable control, including but not limited to mechanical, electronic or communications failure or degradation; acts of God such as severe acts of nature or weather events including floods, fires, earthquakes, hurricanes, or explosions, Rules imposed by Local, State, National Government, War, Acts of terrorism, and epidemics; acts of governmental authorities such as expropriation, condemnation, and changes in laws and regulations; strikes and labor disputes&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;17. Assignment:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;These Terms of Service are personal to You, and are not assignable, transferable or sub- licensable by You except with our prior written consent. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;18. Agency:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;No agency, partnership, joint venture, or employment relationship is created as a result of these Terms of Service and neither party has any authority of any kind to bind the other in any respect.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;19. No Waiver:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;Our failure to enforce any part of these Terms of Service shall not constitute a waiver of our right to later enforce that or any other part of these Terms of Service. Waiver of compliance in any particular instance does not mean that we will waive compliance in the future. In order for any waiver of compliance with these Terms of Service to be binding, we must provide You with written notice of such waiver through one of our authorized representatives.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;20. Support:&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</h5></p>
          <p class="text-justify">We offer email-based, audio-call based and chat-based support. You may access support resources or contact our support by emailing at info@devalayas.com Devalaya does not make any promises regarding how quickly response to Your request for support will be addressed. Any suggestions by us regarding use of the Services shall not be construed as a warranty. If You have any questions regarding the Service, please contact us at info@devalayas.com  or reach out to our Help desk 8951269111. Please note that for the purpose of validation, You shall be required to provide information (including, but not limited to Your email address, contact number or registered mobile number, etc.).&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;21. Headings:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;The section and paragraph headings in these Terms of Service are for convenience only and shall not affect their interpretation.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;<h5>22. Privacy Policy:&nbsp;</h5></p>
          <p class="text-justify"> The Privacy Policy is separately available on the Website, and can be perused by You at Your convenience.&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify"><h5>&nbsp;23. Prasadam:&nbsp;</h5></p>
          <p class="text-justify">&nbsp;Quality and Quantity of the PRASADA is wholly and solely the responsibility of the pujari and the temple from which the order of the prasadam has been made, "Devalaya" will not be responsible in any problem caused by Prasadam whatsoever. The prasadam delivery charges vary from place to place and time to time depending on the delivery logistics.</p>
<p>&nbsp;</p>
<p>Thank you!&nbsp;</p>
          <p>&nbsp;</p>
<p>Email: info@devalayas.com </p>
<p>&nbsp;</p>
<p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
<div class="row"> </div>
      </div>
    </main>
</template>

<script>

export default {  
    mounted(){
      window.scrollTo(0,0);
      let storedMobileNumber = localStorage.getItem("mobileNumber");
    if (
      storedMobileNumber != null &&
      storedMobileNumber != "null" &&
      storedMobileNumber != "+919080706050"
    ) {
      this.$store.dispatch("setsignInMbl", true);
    }
       },
      };

</script>