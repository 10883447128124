<template>
  <footer style="padding-top: 10px" class="bg-image py-5">
    <div style="margin-left: 6%; margin-right: 6%">
      <div class="footer-container">
        <div class="footer-column">
          <img
            style="margin-top: -20px"
            class="mx-auto h-100px"
            src="@/assets/logo.png"
            alt="logo"
          />
          <h6 class="fontie">With Trust & Devotion</h6>
          <p class="fontie" style="text-align: left">
            DEVALAYA is an innovative spiritual tech platform to fulfil the
            needs of Devotee & Pandit ji across the globe, We aim to bring local
            traditions into the global platform, so that the rituals and
            traditions are not lost in the backdrop of modern advancements and
            negligence.
          </p>
          <!-- Add content for About Website -->
        </div>
        <div class="footer-column">
          <h4 class="fontie">Support</h4>
          <p>
            <router-link
              style="color: white; text-align: left"
              to="/TermsAndConditions"
              class="fontie"
              >Terms and conditions</router-link
            >
          </p>
          <p>
            <router-link
              style="color: white; text-align: left"
              to="/CancellationPolicy"
              class="fontie"
              >Cancellation policy</router-link
            >
          </p>
          <p>
            <router-link
              style="color: white; text-align: left"
              to="/RefundPolicy"
              class="fontie"
              >Refund policy</router-link
            >
          </p>
          <p>
            <router-link
              style="color: white; text-align: left"
              to="/PrivacyPolicy"
              class="fontie"
              >Privacy policy</router-link
            >
          </p>
        </div>
        <div class="footer-column">
          <h4 class="fontie">Contact Us</h4>
          <div class="contactClass" v-if="showContactUs">
            <div>
              <p style="color: white; padding-bottom: 2px">
                Phone Number: +91 8951269111
              </p>
            </div>
            <div>
              <p style="color: white; padding-bottom: 2px">
                Suggestion: info@devalayas.com
              </p>
            </div>
            <div>
              <p style="color: white; padding-bottom: 2px">
                Query: support@devalayas.com
              </p>
            </div>
          </div>
          <ul class="list-inline mt-3 mb-0">
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm px-2 bg-facebook mb-0"
                href="https://www.facebook.com/devalayaonlinepujaapp/"
                ><i class="fab fa-fw fa-facebook-f"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-twitter mb-0"
                href="https://twitter.com/devalayas_app"
                ><i class="fab fa-fw fa-twitter"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-linkedin mb-0"
                href="http://www.linkedin.com/in/devalayaonlinepujaapp"
                ><i class="fab fa-fw fa-linkedin-in"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-instagram mb-0"
                href="https://www.instagram.com/devalaya_app/"
                ><i class="fab fa-fw fa-instagram"></i
              ></a>
            </li>
            <li class="list-inline-item">
              <a
                target="_blank"
                class="btn btn-sm shadow px-2 bg-youtube mb-0"
                href="https://www.youtube.com/channel/UCRcWjnz7ybHWy2DfxlHlBpw"
                ><i class="fab fa-fw fa-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
        <!-- <div class="footer-column" v-if="showContactUs">
          <h4 class="fontie">Find Us On</h4>
          <div style="margin-top: 0%">
            <img :src="require(`@/assets/banners/mblscreen.png`)" />
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.devalaya.devotee"
          >
            <img
              style="width: 150px; height: 45px"
              src="https://img10.hotstar.com/image/upload/f_auto,q_90,w_3840/v1661346101/google-playstore"
            />
          </a>
        </div> -->
      </div>
      <!--  -->
      <div class="footer-container">
        <div class="footer-column">
          <h4 class="fontie">Incubated at</h4>
          <div>
            <img :src="require(`@/assets/banners/3.png`)" />
          </div>
        </div>
        <div class="footer-column">
          <h4 class="fontie">Supported by</h4>
          <div>
            <img :src="require(`@/assets/banners/digitalindia-logo.jpg`)" />
          </div>
          <br />
          <div>
            <img :src="require(`@/assets/banners/2.png`)" />
          </div>
        </div>
        <div class="footer-column">
          <h4 class="fontie">Winner Of</h4>
          <div>
            <img :src="require(`@/assets/banners/1.png`)" />
          </div>
        </div>
      </div>
      <!--  -->
      <!-- copyright text -->
      <div class="fontie">
        Copyrights
        <a target="_blank" href="https://www.devalayas.in/" class="fontie"
          >©2023 Devalaya</a
        >. All rights reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      showAboutWebsite: true,
      showQuickLinks: true,
      showSupport: true,
      showContactUs: true,
    };
  },
  computed: {
    // Determine whether to show 2 columns for mobile view
    isMobileView() {
      return window.innerWidth <= 1136; // Adjust the breakpoint as needed
    },
  },
  watch: {
    // Watch for changes in the isMobileView property
    isMobileView(value) {
      // Hide or show columns based on the screen size
      this.showAboutWebsite = value;
      this.showQuickLinks = value;
      this.showSupport = !value;
      this.showContactUs = !value;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    // Handle the window resize event
    handleResize() {
      this.isMobileView = window.innerWidth <= 1136; // Adjust the breakpoint as needed
    },
  },
};
</script>
<style>
.fontie {
  font-family: fangsong;
  color: aliceblue;
}
.contactClass {
  font-family: "Poppins", sans-serif;
}
.bg-image {
  background-color: #ef5b0c;
}
footer {
  font-family: "Poppins", sans-serif;
  background-color: #030000;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.footer-container {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.footer-column {
  font-family: "Poppins", sans-serif;
  flex: 1;
  margin: 10px;
}

.footer-logo {
  margin-top: 20px;
}

@media (max-width: 1136px) {
  .footer-column {
    flex: 1 0 100%;
    font-family: "Poppins", sans-serif;
  }
}
</style>
