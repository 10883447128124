<template>
  <header class="navbar-light header-sticky">
    <!-- Logo Nav START -->
    <nav class="navbar navbar-light navbar-expand-xl">
      <div class="container">
        <!-- Logo START -->
        <router-link to="/" class="navbar-brand">
          <img
            class="light-mode-item navbar-brand-item"
            src="@/assets/logo.png"
            alt="logo"
          />
        </router-link>
        <!-- Logo END -->
        <!-- Responsive navbar toggler -->
        <button
          class="navbar-toggler ms-auto me-3 p-0"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-animation">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </button>
        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav navbar-nav-scroll mx-auto">
            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/"
                ><h6 class="fontie" style="color: white">HOME</h6></router-link
              >
            </li>

            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/aboutpage"
                ><h6 class="fontie" style="color: white">
                  ABOUT US
                </h6></router-link
              >
            </li>

            <li class="nav-item" v-if="this.$store.getters.signInMbl">
              <router-link class="nav-link custom-menu" to="/bookings"
                ><h6 class="fontie" style="color: white">
                  BOOKINGS
                </h6></router-link
              >
            </li>

            <li class="nav-item" v-if="this.$store.getters.signInMbl">
              <router-link class="nav-link custom-menu" to="/suggest"
                ><h6 class="fontie" style="color: white">
                  SUGGEST TEMPLE
                </h6></router-link
              >
            </li>

            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/eventlist"
                ><h6 class="fontie" style="color: white">
                  EVENTS
                </h6></router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link custom-menu" to="/contact"
                ><h6 class="fontie" style="color: white">
                  CONTACT US
                </h6></router-link
              >
            </li>
          </ul>
        </div>

        <!-- Main navbar START -->
        <ul class="nav flex-row align-items-center list-unstyled ms-xl-auto">
          <li class="nav-item ms-3 dropdown">
            <a
              v-if="this.$store.getters.signInMbl"
              class="avatar avatar-lg p-0"
              href="#"
              id="profileDropdown"
              role="button"
              data-bs-auto-close="outside"
              data-bs-display="static"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="../assets/avatar.png" />
            </a>
            <!-- ffffffffffffffffff -->
            <button
              v-if="!this.$store.getters.signInMbl"
              @click="mobileLogin()"
              style="
                background-color: white;
                min-width: 100px;
                min-height: 40px;
                border-radius: 20px;
                border-color: rgb(255, 2, 2);
                border: 0px;
              "
            >
              <i class="fa fa-user" style="color: rgb(248, 49, 4)"></i>
              Login
            </button>
            <!-- ffffffffffffffffff -->
            <ul
              class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
              aria-labelledby="profileDropdown"
            >
              <li
                class="nav-item"
                v-if="this.$store.getters.signInMbl"
                style="padding: 0%"
              >
                <router-link
                  class="nav-link custom-menu"
                  to="/profile"
                  style="padding: 0%"
                  ><h6 class="fontie" style="color: rgb(248, 49, 4)">
                    <i class="bi bi-person-square fa-fw me-2"></i>My Profile
                  </h6></router-link
                >
              </li>
              <hr class="dropdown-divider" />
              <li
                class="nav-item"
                v-if="this.$store.getters.signInMbl"
                style="padding: 0%"
              >
                <router-link
                  class="nav-link custom-menu"
                  style="padding: 0%"
                  to="/bookings"
                  ><h6 class="fontie" style="color: rgb(248, 49, 4)">
                    <i class="fa fa-calendar"></i>&nbsp;&nbsp;My Bookings
                  </h6></router-link
                >
              </li>
              <hr class="dropdown-divider" />
              <li
                class="nav-item"
                v-if="this.$store.getters.signInMbl"
                style="padding: 0%"
              >
                <a
                  class="nav-link custom-menu"
                  style="padding: 0%"
                  href="#"
                  @click.prevent="logout"
                  ><h6 class="fontie" style="color: rgb(248, 49, 4)">
                    <i class="bi bi-power fa-fw me-2"></i>Log Out
                  </h6></a
                >
              </li>
              <li class="nav-item" v-if="!this.$store.getters.signInMbl">
                <a
                  class="nav-link custom-menu"
                  href="#"
                  style="padding: 0%"
                  v-on:click="mobileLogin()"
                >
                  <h6 class="fontie" style="color: #ef5b0c">
                    &nbsp;&nbsp;&nbsp;
                    <i class="bi bi-door-open"></i> &nbsp;&nbsp;&nbsp;Login
                  </h6>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Logo Nav END -->
  </header>
  <main>
    <dialog id="dialog" class="daialbox">
      <div class="box-form">
        <div class="left">
          <img
            style="height: 100%; width: 100%"
            src="@/assets/banners/loginimage.jpg"
            alt="Card image"
          />
        </div>
        <div class="right">
          <div class="position-relative">
            <div>
              <img
                style="height: 200px"
                src="@/assets/banners/Default.png"
                alt="Card image"
              />
            </div>
          </div>
          <div style="text-align: center">
            <br />
            <div v-if="!otpSent">
              <div>
                <select
                  v-model="mobileNumber_code"
                  style="
                    max-width: 100%;
                    min-width: 100%;
                    padding: 0.5rem 1rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    border-radius: 0.5rem;
                    border: 1px solid #cfcfcf;
                  "
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (United States)</option>
                  <option value="+33">+33 (France)</option>
                  <option value="+44">+44 (United Kingdom Country)</option>
                </select>
              </div>

              <input
                :class="{ 'is-invalid': error_loginNumber != '' }"
                type="text"
                v-model="NumberCode"
                style="
                  /* max-width: 100%; */
                  padding: 0.5rem 1rem;
                  font-size: 1rem;
                  font-weight: 400;
                  line-height: 1.5;
                  border-radius: 0.5rem;
                  border: 1px solid #cfcfcf;
                "
                placeholder="Enter mobile number"
                maxlength="10"
              />
              <span v-if="error_loginNumber != ''" class="invalid-feedback">{{
                error_loginNumber
              }}</span>
              <!-- <div> -->
              <br />
              <div>
                <p style="font-size: x-small; padding: 10px">
                  By proceeding you agree to the
                  <a
                    href="https://www.devalayas.com/#/TermsAndConditions"
                    target="_blank"
                    style="color: rgb(252, 112, 5)"
                    class="fontie"
                  >
                    Terms and conditions
                  </a>
                  and
                  <a
                    href="https://www.devalayas.com/#/PrivacyPolicy"
                    target="_blank"
                    style="color: rgb(252, 112, 5)"
                    class="fontie"
                  >
                    Privacy policy
                  </a>
                  of Astro Devalaya
                </p>
              </div>
              <br />
              <div>
                <button
                  class="button p-0 mb-0"
                  style="vertical-align: middle"
                  @click="sendCode"
                >
                  Verify <i class="bi bi-check2-circle"></i>
                </button>
              </div>

              <!-- </div> -->
            </div>
            <div :key="elementKey" id="recaptcha-container"></div>
            <br />
            <div v-if="otpSent">
              <div>
                <input
                  type="text"
                  v-model="verificationCode"
                  style="
                    text-align: center;
                    max-width: 100%;
                    min-width: 100%;
                    padding: 0.5rem 1rem;
                    font-size: 1rem;
                    font-weight: 400;
                    line-height: 1.5;
                    border-radius: 0.5rem;
                    border: 1px solid #cfcfcf;
                  "
                  placeholder="Enter OTP"
                  maxlength="6"
                />
              </div>
              <span v-if="otp_error != ''">{{ otp_error }}</span>
              <br />
              <button
                class="button p-0 mb-0"
                style="vertical-align: middle"
                @click="verifyCode"
              >
                Send <i class="bi bi-send"></i>
              </button>

              <p v-if="!resendButton">
                {{ minutes }}:{{ seconds < 10 ? "0" + seconds : seconds }}
              </p>
              <div>
                <a
                  v-if="resendButton"
                  style="vertical-align: middle; cursor: pointer"
                  @click="ResendCode"
                >
                  Resend<i class="bi bi-arrow-clockwise"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        <i
          class="fas fa-times-circle"
          style="
            font-size: 20px;
            color: rgb(42, 38, 38);
            float: right;
            cursor: pointer;
          "
          v-on:click="closeModal()"
        ></i>
      </div>
      <!-- yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy -->
      <!-- <div>
        <div class="container d-flex px-0 px-sm-4">
          <div class="row justify-content-center align-items-center m-auto">
            <div class="col-12">
              <div class="position-relative">
                <div>
                  <img
                    style="height: 200px"
                    src="@/assets/banners/Default.png"
                    alt="Card image"
                  />
                </div>
              </div>
              <div style="text-align: center">
                <br />
                <div v-if="!otpSent">
                  <div>
                    <select
                      v-model="mobileNumber_code"
                      style="
                        max-width: 100%;
                        padding: 0.5rem 1rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        border-radius: 0.5rem;
                        border: 1px solid #cfcfcf;
                      "
                    >
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                      <option value="+33">+33</option>
                      <option value="+44">+44</option>
                    </select>
                  </div>

                  <input
                    :class="{ 'is-invalid': error_loginNumber != '' }"
                    type="text"
                    v-model="NumberCode"
                    style="
                      padding: 0.5rem 1rem;
                      font-size: 1rem;
                      font-weight: 400;
                      line-height: 1.5;
                      border-radius: 0.5rem;
                      border: 1px solid #cfcfcf;
                    "
                    placeholder="Enter mobile number"
                    maxlength="10"
                  />
                  <span
                    v-if="error_loginNumber != ''"
                    class="invalid-feedback"
                    >{{ error_loginNumber }}</span
                  >
                  <div>
                    <br />
                    <button
                      class="button p-0 mb-0"
                      style="vertical-align: middle"
                      @click="sendCode"
                    >
                      Verify <i class="bi bi-check2-circle"></i>
                    </button>
                    <p style="font-size: x-small; padding: 10px">
                      By proceeding you agree to the
                      <a
                        href="https://www.devalayas.com/#/TermsAndConditions"
                        target="_blank"
                        style="color: rgb(252, 112, 5)"
                        class="fontie"
                      >
                        Terms and conditions
                      </a>
                      and
                      <a
                        href="https://www.devalayas.com/#/PrivacyPolicy"
                        target="_blank"
                        style="color: rgb(252, 112, 5)"
                        class="fontie"
                      >
                        Privacy policy
                      </a>
                      <br />
                      of Astro Devalaya
                    </p>
                  </div>
                </div>
                <div :key="elementKey" id="recaptcha-container"></div>
                <br />
                <div v-if="otpSent">
                  <div>
                    <input
                      type="text"
                      v-model="verificationCode"
                      style="
                        width: 100%;
                        padding: 0.5rem 1rem;
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                        border-radius: 0.5rem;
                        border: 1px solid #cfcfcf;
                      "
                      placeholder="Enter OTP"
                      maxlength="6"
                    />
                  </div>
                  <span v-if="otp_error != ''">{{ otp_error }}</span>
                  <br />
                  <button
                    class="button p-0 mb-0"
                    style="vertical-align: middle"
                    @click="verifyCode"
                  >
                    Send <i class="bi bi-send"></i>
                  </button>

                  <p v-if="!resendButton">
                    {{ minutes }}:{{ seconds < 10 ? "0" + seconds : seconds }}
                  </p>
                  <div>
                    <a
                      v-if="resendButton"
                      style="vertical-align: middle; cursor: pointer"
                      @click="ResendCode"
                    >
                      Resend<i class="bi bi-arrow-clockwise"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy -->
    </dialog>
  </main>
</template>
<script>
import firebase from "@/store/firebase.js";
import "firebase/database";
import "firebase/compat/auth";
// import {GoogleAuthProvider,signInWithPopup,getAuth} from "firebase/auth";
import { parsePhoneNumberFromString } from "libphonenumber-js";

export default {
  name: "Header",
  data() {
    return {
      mobileNumber_code: "+91",
      otp_error: "",
      elementKey: 0,
      error_loginNumber: "",
      isValidPhoneNumber: false,
      resendButton: false,
      otpSent: false,
      countryCode: "",
      phoneNumber: "",
      NumberCode: "",
      verificationCode: "",
      verificationId: "",
      user: {
        mobile_number: "",
        login_token: "123",
        app_version: "1",
        device_model: "Browser",
        user_type: "Devotee",
      },
      displayName: "",
      googleEmail: "",
      minutes: 3,
      seconds: 0,
      timer: null,
      recaptcha: "",
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    ResendCode() {
      let vm = this;
      vm.minutes = 3;
      vm.seconds = 0;
      vm.timer = null;
      this.user.mobile_number = "";
      this.phoneNumber = "";
      this.NumberCode = "";
      vm.otpSent = false;
      vm.resendButton = false;
      this.elementKey = 1;
    },
    startCountdown() {
      let vm = this;
      this.timer = setInterval(() => {
        if (this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
          // Timer has reached zero, you can perform any actions here
          vm.resendButton = true;
        } else {
          if (this.seconds === 0) {
            this.minutes--;
            this.seconds = 59;
          } else {
            this.seconds--;
          }
        }
      }, 1000);
    },

    mobileLogin() {
      let vm = this;
      document.getElementById("dialog").showModal();
      vm.minutes = 3;
      vm.seconds = 0;
      vm.timer = null;
      this.user.mobile_number = "";
      this.phoneNumber = "";
      this.NumberCode = "";
      vm.otpSent = false;
      vm.resendButton = false;
    },
    login(number_login) {
      let vm = this;
      let loader = vm.$loading.show();
      this.user.mobile_number = number_login;
      vm.$store
        .dispatch("auth", { uri: "auth/", data: vm.user })
        .then((response) => {
          loader.hide();
          vm.$store.dispatch("setmobileNumber", this.user.mobile_number);
          vm.$store.dispatch("setsignInMbl", true);
          vm.$store.dispatch("setUser", response.data);
          vm.$store.dispatch("setToken", response.data.token);
          vm.$store.dispatch("setTokenType", "Token");
        })
        .catch((error) => {
          loader.hide();
          vm.errors = error.response.data.error;
          vm.$store.dispatch("error", error.response.data.message);
          vm.$store.dispatch("setsignInMbl", false);
        });
    },
    verifyCode() {
      let vm = this;
      this.otp_error = "";
      const credential = firebase.auth.PhoneAuthProvider.credential(
        this.verificationId,
        this.verificationCode
      );
      firebase
        .auth()
        .signInWithCredential(credential)
        .then((result) => {
          this.user.mobile_number =
            this.mobileNumber_code.trim() + this.NumberCode.trim();
          vm.$store.dispatch("setsignInMbl", true);
          document.getElementById("dialog").close();
          vm.login(this.user.mobile_number);
          this.elementKey += 1;
        })
        .catch((error) => {
          // this.elementKey += 1;
          console.log(error);
          this.otp_error = "Invalid OTP";
        });
    },

    sendCode() {
      let vm = this;
      const appVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
      const regex = /^\+[0-9]{11,12}$/;
      vm.error_loginNumber = "";
      vm.phoneNumber = vm.mobileNumber_code.trim() + this.NumberCode.trim();
      vm.isValidPhoneNumber = regex.test(vm.phoneNumber);
      if (vm.mobileNumber_code == "") {
        vm.error_loginNumber = "Please select country code";
      } else if (this.NumberCode == "") {
        vm.error_loginNumber = "Please enter mobile number";
      } else if (!vm.isValidPhoneNumber) {
        vm.error_loginNumber =
          "Enter your number in the format '+919876543210'.";
      } else {
        const phoneNumber = parsePhoneNumberFromString(this.phoneNumber);

        firebase
          .auth()
          .signInWithPhoneNumber(this.phoneNumber, appVerifier)
          .then((confirmationResult) => {
            this.verificationId = confirmationResult.verificationId;
            this.otpSent = true;
            this.startCountdown();
            vm.phoneNumber = phoneNumber; // Assuming this.phoneNumber is a property of your component
          })
          .then((result) => {
            console.log("Phone authentication successful");
          })
          .catch((error) => {
            this.error_loginNumber = "Please try again later";
            console.error(error.message);
            this.elementKey += 1;
          });
      }
    },
    closeModal() {
      document.getElementById("dialog").close();
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style>
.login-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #000000;
  border: none;
  border-radius: 5px;
}

.login-button:hover {
  background-color: #f0f0f0;
}
.custom-menu {
  font-size: auto;
  color: rgb(5, 5, 5);
  font-family: commanders;
}

.cancel-symbol {
  color: red; /* Adjust the color as needed */
  font-size: 30px; /* Adjust the font size as needed */
  cursor: pointer; /* Optional: Change cursor on hover to indicate it's clickable */
  float: right;
}

.box-form {
  margin: 0 auto;
  width: 80%;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex: 1 1 100%;
  align-items: stretch;
  justify-content: space-between;
  /* box-shadow: 0 0 20px 6px #090b6f85; */
}
@media (max-width: 980px) {
  .box-form {
    flex-flow: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
  }
}
.box-form div {
  height: auto;
}
.box-form .left {
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://i.pinimg.com/736x/5d/73/ea/5d73eaabb25e3805de1f8cdea7df4a42--tumblr-backgrounds-iphone-phone-wallpapers-iphone-wallaper-tumblr.jpg");
  overflow: hidden;
}
.box-form .left .overlay {
  padding: 30px;
  width: 100%;
  height: 100%;
  background: #5961f9ad;
  overflow: hidden;
  box-sizing: border-box;
}
.box-form .left .overlay h1 {
  font-size: 10vmax;
  line-height: 1;
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 20px;
}
.box-form .left .overlay span p {
  margin-top: 30px;
  font-weight: 900;
}
.box-form .left .overlay span a {
  background: #3b5998;
  color: #ffffff;
  margin-top: 10px;
  padding: 14px 50px;
  border-radius: 100px;
  display: inline-block;
}
.box-form .left .overlay span a:last-child {
  background: #1dcaff;
  margin-left: 30px;
}
.box-form .right {
  padding: 40px;
  /* overflow: hidden; */
}
@media (max-width: 980px) {
  .box-form .right {
    width: 100%;
  }
}
.box-form .right h5 {
  font-size: 6vmax;
  line-height: 0;
}
.box-form .right p {
  font-size: 14px;
  color: #b0b3b9;
}
.box-form .right .inputs {
  overflow: hidden;
}
.box-form .right input {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 2px solid #b0b3b9;
}
.box-form .right .remember-me--forget-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.box-form .right .remember-me--forget-password input {
  margin: 0;
  margin-right: 7px;
  width: auto;
}
.box-form .right button {
  color: #fff;
  font-size: 16px;
  padding: 12px 35px;
  border-radius: 50px;
  display: inline-block;
  border: 0;
  outline: 0;
  background: linear-gradient(140deg, #ff9800, #ff5757);
}

.daialbox {
  border: 1px solid #ffffff00;
  border-radius: 8px;
  padding: 0%;
  background-color: transparent;
}
</style>
