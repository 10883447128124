<template>
  <main>
    <div style="background-color: white">
      <div class="container-fluid header bg-primary p-0 mb-5">
        <div
          class="row g-0 align-items-center flex-column-reverse flex-lg-row"
          style="background-color: #fde5d1"
        >
          <div
            class="col-lg-5 p-5 wow fadeIn"
            data-wow-delay="0.1s"
            style="background-color: #fde5d1"
          >
            <!-- <img class="img-fluid" style="height: 150px;width: 150px;" src="@/assets/banners/Default.png" alt=""> -->
            <div class="responsive-box" style="border-radius: 20px">
              <p style="text-align: left">
                Devalaya acts as a conduit between devotees and temples, with
                our network of representatives orchestrating rituals on your
                behalf. While not officially affiliated with temples, we provide
                transparent services inclusive of temple fees, puja materials,
                priest gratuity, prasadam shipping, and service charges.
              </p>
            </div>
            <br />
            <h4 style="text-align: left">
              Participate in Vedic rituals during online pujas dedicated to your
              Kuladevata, Grama Devata,and renowned temples in India
              through<span style="color: orangered">&nbsp;Astro Devalaya</span>
            </h4>

            <div style="float: left">
              <a
                href="https://play.google.com/store/apps/details?id=com.devalaya.devotee"
                target="_blank"
              >
                <img
                  style="width: 150px; height: 45px"
                  src="https://img10.hotstar.com/image/upload/f_auto,q_90,w_3840/v1661346101/google-playstore"
                /> </a
              >&nbsp;
              <a
                href="https://play.google.com/store/apps/details?id=com.devalaya.devotee"
                target="_blank"
              >
                <img
                  src="	https://img10.hotstar.com/image/upload/f_auto,q_90,w_256/v1661346071/ios-appstore"
                  style="width: 150px; height: 45px"
                  alt="Download Groww App on apple store"
                />
              </a>
            </div>
          </div>
          <div
            class="col-lg-6 wow fadeIn"
            data-wow-delay="0.5s"
            style="background-color: #fde5d1"
          >
            <div class="owl-carousel header-carousel">
              <div class="owl-carousel-item position-relative">
                <img
                  class="img-fluid"
                  src="@/assets/banners/Heliotrope.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Event Start -->
    <section
      class="do_section"
      v-if="counts != '0'"
      style="background-color: white; padding-left: 1px; padding-right: 1px"
    >
      <div>
        <div class="heading_container" style="text-align: center">
          <h2 style="color: #ef5b0c">ongoing Event's</h2>
          <p>
            Immerse yourself in the enchantment of a unique puja, a captivating
            ritual that uplifts the spirit and brings blessings into your life.
          </p>
        </div>
        <carousel
          :items-to-show="isDesktop ? 2 : 1"
          autoplay="10000"
          transition="500"
          wrapAround="false"
        >
          <slide v-for="item in events" :key="item">
            <div v-if="item.event_devotee == true">
              <div
                class="carousel-itemswithjs active"
                style="
                  padding-right: 15px;
                  padding-left: 15px;
                  max-width: 600px;
                  max-height: 500px;
                "
              >
                <div class="en" style="display: initial">
                  <div style="position: relative">
                    <img
                      :src="item.temple.images[0]?.image"
                      style="border-radius: 8px 8px 0px 0px"
                      class="shadow-lg w-100 ml-auto mr-auto img-fluid lazyloaded"
                    />
                    <div
                      class="carousel-caption-custom-1"
                      style="
                        background-color: rgb(13 0 99);
                        border-radius: 10px;
                      "
                    >
                      <div class="addwrap text-left">
                        <div class="col pb-2 titletext">
                          {{ item.pooja.name }}
                        </div>
                        <div class="col subtitletext">
                          {{ item.pooja.details }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <a
                    @click.prevent="viewEvent(item.name)"
                    style="cursor: pointer"
                  >
                    <div class="">
                      <div
                        style="
                          background-color: rgb(255, 94, 0);
                          border-radius: 0px 0px 8px 8px;
                          padding: 30px;
                        "
                        data-key="parti"
                      >
                        <div
                          class="fontie"
                          style="float: left; padding-bottom: 10px"
                        >
                          <h6 style="color: white">
                            {{ moment(item.end).format("MMMM Do") }}
                          </h6>
                        </div>
                        <div
                          class="fontie"
                          style="float: right; padding-bottom;: 10px"
                        >
                          <h6 style="color: white">
                            View Details
                            <i
                              class="fa fa-arrow-right pl-2 text-light my-auto"
                              style="position: relative; font-size: 16px"
                              aria-hidden="true"
                            ></i>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </slide>
          <template #addons>
            <pagination />
          </template>
        </carousel>
      </div>
    </section>
    <div>
      <h3 class="col-12 text-center" style="color: #ef5b0c">
        EXPLORE MORE TEMPLES
      </h3>
      <p>
        Reserve Pujas under your name and for your family at over 1000 renowned
        temples in India.
      </p>
      <div class="row"></div>
      <div class="row">
        <div class="col-xl-8 mx-auto">
          <div
            class="bg-blur bg-white bg-opacity-10 border border-light border-opacity-25 rounded-3 p-4"
          >
            <div class="row g-3 justify-content-center align-items-center">
              <div class="col-lg-10">
                <div class="form-input-dropdown position-relative">
                  <input
                    v-model="meta.search"
                    placeholder="Search for Temple"
                    class="form-control form-control-lg me-1 ps-5"
                    @keyup.enter="search"
                    type="text"
                  />
                </div>
              </div>
              <div class="col-lg-2 d-grid">
                <a
                  class="btn btn-lg btn-primary mb-0"
                  href="#"
                  @click.prevent="search"
                  >Search</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <!--  -->
      <div class="container">
        <div class="row g-4">
          <div
            class="col-sm-6 col-lg-4 col-xl-3"
            v-for="(card, index) in cards"
            :key="index"
          >
            <div>
              <a href="#" @click.prevent="viewTemple(card.id)">
                <div
                  class="card-hover"
                  @mouseover="showDetails(index)"
                  @mouseleave="hideDetails"
                >
                  <img
                    :src="card.images[0]?.image"
                    :alt="card.title"
                    class="card-hover-image"
                  />
                  <div v-if="card.showDetails" class="card-hover-popup">
                    <h6 style="color: #80f3e7">🙏 {{ card.name }}</h6>
                    <h6 style="color: #bc990c">📍 {{ card.area }}</h6>
                    <div>
                      Participate Now
                      <i
                        class="fa fa-arrow-right pl-2 text-light my-auto"
                        style="position: relative; font-size: 16px"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <h6>{{ card.name }}</h6>
              </a>
            </div>
          </div>
          <Paginate
            style="margin-bottom: 20px"
            class="pagination pagination-sm"
            :maxPage="meta.maxPage"
            :totalPages="meta.lastPage"
            :currentPage="meta.page"
            @pagechanged="onPageChange"
          />
        </div>
      </div>
    </div>

    <section class="app-info-section" style="background-color: #703c66">
      <!-- Part 1: Info about the app -->
      <div
        class="app-info"
        style="
          background-color: #703c66;
          text-align: left;
          padding-left: 45px;
          margin-top: 5%;
        "
      >
        <h6 style="color: rgb(157, 150, 255)">
          Trusted by more than 300k devotees in India and over 10k NRIs.
        </h6>
        <h2 style="color: white">
          The Devotional & Astrology App cherished by many in India.
        </h2>
        <p style="color: white">
          We embark on a mission to support one billion Indians in their
          spiritual and devotional endeavors, leading them toward a state of
          happiness, peace, and contentment.
        </p>
      </div>

      <div class="divider-section">
        <div class="divider-row">
          <div class="divider-item">
            <img
              class="img-fluid"
              src="@/assets/banners/downloadIcon.png"
              alt=""
            />
            <p style="color: white">
              <RollingNumber
                :targetNumber="targetNumber1"
              />&nbsp;+&nbsp;Downloads
            </p>
            <p style="color: white">
              Building Trust, One Crore Users Strong: Your Journey, Our
              Commitment!
            </p>
          </div>
          <div class="divider-item">
            <img
              class="img-fluid"
              src="@/assets/banners/totaldevotee.png"
              alt=""
            />
            <p style="color: white">
              <RollingNumber
                :targetNumber="targetNumber2"
              />&nbsp;+&nbsp;Worshipers
            </p>
            <p style="color: white">
              Have placed their trust in us during their spiritual journey.
            </p>
          </div>
        </div>
        <div class="divider-row">
          <div class="divider-item">
            <img
              class="img-fluid"
              src="@/assets/banners/totalpuja.png"
              alt=""
            />
            <p style="color: white">
              <RollingNumber :targetNumber="targetNumber3" />&nbsp;+&nbsp;Puja's
            </p>
            <p style="color: white">
              Countless worshipers seek divine blessings at top Indian temples
              through our platform.
            </p>
          </div>
          <div class="divider-item">
            <img
              class="img-fluid"
              src="@/assets/banners/totaltemple.png"
              alt=""
            />
            <p style="color: white">
              <RollingNumber
                :targetNumber="targetNumber4"
              />&nbsp;+&nbsp;Temples
            </p>
            <p style="color: white">
              Have placed their trust in over 800+ active temples and devoted
              priests.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!--  -->
    <!--  -->
    <section class="do_section layout_padding">
      <div class="container">
        <div class="heading_container">
          <h2 style="color: #ef5b0c">Special Puja's</h2>
          <p>
            Experience the magic of a special puja, an enchanting ritual that
            elevates the spirit and brings blessings into your life.
          </p>
        </div>
        <div class="do_container">
          <div style="padding-left: 50px">
            <!-- <div class="img-box"> -->
            <a href="#" @click.prevent="viewsplPooja(5)">
              <img
                src="@/assets/banners/Spl_puja1.png"
                class="img-box"
                alt=""
              />
            </a>
            <!-- </div> -->
            <div class="detail-box">
              <h6>
                {{ spltitle1 }}
              </h6>
            </div>
          </div>
          <div style="padding-left: 50px">
            <!-- <div class="img-box"> -->
            <a href="#" @click.prevent="viewsplPooja(4)">
              <img
                src="@/assets/banners/Spl_puja2.png"
                class="img-box"
                alt=""
              />
            </a>
            <!-- </div> -->
            <div class="detail-box">
              <h6>
                {{ spltitle2 }}
              </h6>
            </div>
          </div>
          <div style="padding-left: 50px">
            <!-- <div class="img-box"> -->
            <a @click.prevent="viewsplPooja(3)">
              <img
                src="@/assets/banners/Spl_puja3.png"
                class="img-box"
                alt=""
              />
            </a>
            <!-- </div> -->
            <div class="detail-box">
              <h6>
                {{ spltitle3 }}
              </h6>
            </div>
          </div>
          <div style="padding-left: 50px">
            <!-- <div class="img-box"> -->
            <a href="#" @click.prevent="viewsplPooja(2)">
              <img
                src="@/assets/banners/Spl_puja4.png"
                class="img-box"
                alt=""
              />
            </a>
            <!-- </div> -->
            <div class="detail-box">
              <h6>
                {{ spltitle4 }}
              </h6>
            </div>
          </div>
          <div style="padding-left: 50px">
            <!-- <div class="img-box"> -->
            <a href="#" @click.prevent="viewsplPooja(1)">
              <img
                src="@/assets/banners/Spl_puja5.png"
                class="img-box"
                alt=""
              />
            </a>
            <!-- </div> -->
            <div class="detail-box">
              <h6>
                {{ spltitle5 }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import Paginate from "@/components/Pagination.vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import "swiper/css/effect-cube";
import RollingNumber from "@/components/RollingNumber.vue"; // import required modules
import { EffectCube } from "swiper/modules";
import { EffectCards } from "swiper/modules";

var today = new Date();
var moment = require("moment");

export default {
  name: "ClarityIntegration",
  components: {
    RollingNumber,
    Carousel,
    Slide,
    Swiper,
    SwiperSlide,
    Paginate,
    Pagination,
    Navigation,
  },
  setup() {
    return {
      modules: [EffectCube, EffectCoverflow, Pagination, Autoplay, EffectCards],
    };
  },
  data() {
    return {
      // cousal : 2,
      isDesktop: false,
      targetNumber1: 50000,
      targetNumber2: 350000,
      targetNumber3: 100000,
      targetNumber4: 800,
      selectedItem: null,
      videoSource: "require(`@/assets/banners/astro.mp4`)", // Replace with the path to your video file
      autoplay: {
        delay: 400,
        disableOnInteraction: true,
      },
      isDragging: false,
      selectedIndex: 0,
      //
      selectedSign: "",
      moment: moment,
      meta: {
        page: 1,
        size: 6,
        count: 0,
        maxPage: 1,
        lastPage: 1,
        search: "",
        ordering: "name_en",
        date: "2",
      },
      cards: [],
      temples: [],
      date: "2",
      events: [],
      splPooja: [],
      dates: "",
      counts: "0",
      splcounts: "0",
      daily1: "",
      daily2: "",
      daily3: "",
      daily4: "",
      userName: "",
      profile_image: "",
      spltitle1: "",
      spltitle2: "",
      spltitle3: "",
      spltitle4: "",
      spltitle5: "",
      splimage1: "",
      splimage2: "",
      splimage3: "",
      splimage4: "",
      splimage5: "",
      splid1: "",
      splid2: "",
      splid3: "",
      splid4: "",
      splid5: "",
    };
  },

  mounted() {
    // Create a <script> element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://www.clarity.ms/tag/m69k9jkd8b";

    // Append the script to the <body> element
    document.body.appendChild(script);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.scrollTo(0, 0);
    this.getTemples();
    this.todaysDate();
    this.eventsDetails();
    this.specialPooja();
    this.getProfile();
    this.startRolling();
    this.checkScreenSize();
    window.addEventListener("resize", this.checkScreenSize);
    let storedMobileNumber = localStorage.getItem("mobileNumber");
    if (
      storedMobileNumber != null &&
      storedMobileNumber != "null" &&
      storedMobileNumber != "+919080706050"
    ) {
      this.$store.dispatch("setsignInMbl", true);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("resize", this.checkScreenSize);
  },
  computed: {
    numberArray() {
      return this.currentNumber.toString().split("").map(Number);
    },
    circleDiameter() {
      // You can adjust this logic based on your requirements
      const windowWidth = window.innerWidth;
      return `${Math.min(windowWidth, 900)}px`; // Limit the maximum diameter to 600px
    },
    circleRadius() {
      return parseInt(this.circleDiameter) / 1;
    },
    selectedImage() {
      return this.images[this.selectedIndex];
    },
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  methods: {
    checkScreenSize() {
      // Update the isDesktop data property based on the window width
      this.isDesktop = window.innerWidth >= 768; // Adjust the breakpoint as needed
    },
    startRolling() {
      const increment = Math.ceil(
        this.targetNumber / (this.animationDuration / 100)
      );

      const intervalId = setInterval(() => {
        this.currentNumber += increment;

        if (this.currentNumber >= this.targetNumber) {
          this.currentNumber = this.targetNumber;
          clearInterval(intervalId);
        }
      }, 100);
    },

    showDetails(index) {
      this.cards[index].showDetails = true;
    },
    hideDetails() {
      this.cards.forEach((card) => {
        card.showDetails = false;
      });
    },
    handleResize() {
      this.$forceUpdate();
    },
    startDrag() {
      this.isDragging = true;
    },
    endDrag() {
      this.isDragging = false;
    },
    drag(event) {
      if (this.isDragging) {
        const container = this.$el.querySelector(".circle-container");
        const rect = container.getBoundingClientRect();
        const centerX = rect.left + rect.width / 2;
        const centerY = rect.top + rect.height / 2;

        const angle = Math.atan2(
          event.clientY - centerY,
          event.clientX - centerX
        );
        let index = Math.round(
          (((angle + Math.PI) % (Math.PI * 2)) / (Math.PI * 2)) *
            this.images.length
        );
        index = (index + this.images.length) % this.images.length;

        this.selectedIndex = index;
      }
    },
    getImageStyle(index) {
      const totalImages = this.images.length;
      const angleStep = (Math.PI * 2) / totalImages;

      const angle = index * angleStep;
      const x = Math.cos(angle) * this.circleRadius + 50 + "%";
      const y = Math.sin(angle) * this.circleRadius + 50 + "%";

      return {
        transform: `translate(-50%, -50%) translate(${x}, ${y})`,
      };
    },
    handleDateInput(selectedSign) {
      let vm = this;
      this.selectedSign = selectedSign;
      let loader = this.$loading.show();
      vm.$store
        .dispatch("get", { uri: "astrology/horoscope/" + this.selectedSign })
        .then((updateinfo) => {
          loader.hide();
          vm.daily1 = updateinfo.data.daily.other[0];
          vm.daily2 = updateinfo.data.daily.other[1];
          vm.daily3 = updateinfo.data.daily.other[2];
          vm.daily4 = updateinfo.data.monthly.content[0].content;
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
      // Access the selected date from the event
    },
    getProfile() {
      let vm = this;
      let loader = this.$loading.show();
      vm.$store
        .dispatch("get", { uri: "devotee/profile/" })
        .then((updateinfo) => {
          loader.hide();
          // console.log("profile",updateinfo);
          vm.profile_image = updateinfo.data.profile_image;
          vm.emailId = updateinfo.data.email;
          vm.mobileNo = updateinfo.data.mobile_number;
          vm.userName = updateinfo.data.name;
        })
        .catch((error) => {
          loader.hide();
          console.log(error);
        });
    },
    viewsplPooja(catsch) {
      this.$router.push("splpooja/" + catsch);
    },
    specialPooja() {
      let vm = this;
      let loader = this.$loading.show();
      vm.$store
        .dispatch("get", { uri: "category/category/" })
        .then((response) => {
          loader.hide();
          vm.spltitle1 = "Festival Pujas";
          vm.spltitle2 = response.data.results[1].name;
          vm.spltitle3 = response.data.results[2].name;
          vm.spltitle4 = response.data.results[3].name;
          vm.spltitle5 = response.data.results[4].name;
          vm.splimage1 = response.data.results[0].image;
          vm.splimage2 = response.data.results[1].image;
          vm.splimage3 = response.data.results[2].image;
          vm.splimage4 = response.data.results[3].image;
          vm.splimage5 = response.data.results[4].image;
          vm.splid1 = response.data.results[0].id;
          vm.splid2 = response.data.results[1].id;
          vm.splid3 = response.data.results[2].id;
          vm.splid4 = response.data.results[3].id;
          vm.splid5 = response.data.results[4].id;
          vm.splPooja = response.data.results;
          vm.splcounts = response.data.count;
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
    getTemples() {
      let vm = this;
      let uri =
        "devotee/temple/?page=" +
        vm.meta.page +
        "&size=" +
        vm.meta.size +
        "&search=" +
        vm.meta.search +
        "&ordering=" +
        vm.meta.ordering;
      vm.$store.dispatch("get", { uri: uri }).then(function (response) {
        vm.cards = response.data.results;
        vm.meta.count = response.data.count;
        vm.meta.lastPage = Math.ceil(vm.meta.count / vm.meta.size);
        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
      });
    },
    onPageChange(page) {
      this.meta.page = page;
      this.getTemples();
    },
    search() {
      this.meta.page = 1;
      this.getTemples();
    },
    viewTemple(id) {
      this.$router.push("temples/" + id);
    },
    viewEvent(name) {
      this.$router.push("search/" + name);
    },
    todaysDate() {
      let vm = this;
      vm.date = today.getDay();
    },
    eventsDetails() {
      let vm = this;
      let loader = this.$loading.show();
      vm.$store
        .dispatch("get", { uri: "devotee/event/" })
        .then((response) => {
          loader.hide();
          vm.events = response.data.results;
          vm.counts = response.data.count;
          // if (count > 2) {
          //   vm.cousal = 3;
          // } else {
          //   vm.cousal = 2;
          // }
        })
        .catch((errors) => {
          loader.hide();
          console.log(errors);
        });
    },
  },
};
</script>
<style>
video {
  pointer-events: none; /* Disable pointer events on the video */
}
.imagesize {
  height: 350px;
}
.video-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.responsive-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
.temple {
  border-radius: 9px;
  object-fit: cover;
  width: 100%;
  height: 250px;
}
.no-padding {
  background-color: transparent;
  margin-top: -70px;
}
@media only screen and (max-width: 375px) {
  .carousel__slide .carousel__slide--visible .carousel__slide--active {
    width: 100% !important;
  }
  .carousel__slide
    .carousel__slide--clone
    .carousel__slide--visible
    .carousel__slide--prev {
    width: 100% !important;
  }
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .carousel-caption {
    top: 300px;
    text-align: left;
    background-color: white;
    padding: 35px;
    width: fit-content;
  }
  .carousel-item.active {
    display: block;
  }
  .ba-1 {
    background-size: cover;
    background-position: top left;
  }
}
.imagetext {
  top: 150px;
  float: left;
}
.spls {
  margin: 0.1em 0;
  padding: 0.2em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: normal;
  justify-content: space-evenly;
  align-items: center;
  justify-content: space-evenly;
  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: top 0.3s;
  padding-top: 40px;
  padding-bottom: 30px;
}
.splimg {
  position: relative;
  height: 65px;
  width: 65px;
  margin: 0.2em auto;
  border: 2px solid var(--primary);
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
}
.splimgEvent {
  height: 250px;
  min-width: 200px;
}
.spl {
  margin: 0.2em 0.5em;
  text-align: center;
  font-family: Poppins;
  font-size: 0.75em;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .splimgEvent {
    height: 50px;
    min-width: 25px;
  }
  .single-list-topics-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    max-height: 100px;
    background: #fff;
    border-radius: 3px;
    margin-right: 10px;
    margin-bottom: 20px;
    border-radius: 20px;
  }
}
@media (min-width: 1200px) {
  .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 35%;
    margin-top: 20px;
  }
}
.vertical-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin: 10px;
  overflow: hidden;
}

.card-content {
  display: flex;
}

.card-image {
  flex: 1;
}

.card-image img {
  width: 100%;
  height: auto;
  border-radius: 8px 0 0 8px;
}

.card-title {
  flex: 2;
  padding: 20px;
}

.card-title h2 {
  margin: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  font-family: "Dosis", sans-serif;
  text-align: center;
}
.clear {
  clear: both;
}
.bg-image {
  position: sticky;
}
.card {
  border: 1px solid #ccc;
  border-radius: 8px;
}
/* circulR SLIDE */
.circle-container {
  position: relative;
  margin: auto;
  overflow: hidden;
}

.circle-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.circle-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.selected-image {
  text-align: center;
  margin-top: 10px;
}

.selected-image img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.selected-image p {
  margin: 5px 0;
  font-weight: bold;
}
.bimage {
  background-image: url(https://media.giphy.com/media/U3qYN8S0j3bpK/giphy.gif);
}
@media (min-width: 992px) {
  .col-lg-6 {
    color: transparent;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.carousel {
  transform-style: preserve-3d;
  /* display: flex; */
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.w-100 {
  width: 100% !important;
}
.carousel {
  position: relative;
}
.carousel-container {
  overflow: hidden;
  width: max-content;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.w-100 {
  width: 100% !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
/* popup */
.card-hover {
  position: relative;
  overflow: hidden;
  /* width: 200px; */
  height: 300px;
  margin: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.3s;
}

.card-hover:hover {
  transform: scale(1.3);
}

.card-hover-image {
  align-items: center;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-hover-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s;
}

.card-hover:hover .card-hover-popup {
  opacity: 1;
  transform: translateY(0);
}
.circular-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.circle {
  position: relative;
  width: 240px;
  height: 240px;
}

.gallery-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: center center;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.gallery-item img {
  width: 50px; /* Adjust the size of the images */
  height: 50px; /* Adjust the size of the images */
  border-radius: 50%;
}

.gallery-item img:hover {
  border: 2px solid #ff5733;
}
/* Event New */
@media (min-width: 992px) {
  .col-lg-6 {
    color: transparent;
  }
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
.carousel-container * {
  box-sizing: border-box;
}
.carousel-caption-custom-1 {
  padding: 12px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  z-index: 10;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 7%;
  width: 88%;
  border-top: 1px #fff solid;
}

.addwrap {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.text-left {
  text-align: left !important;
}
@media (min-width: 768px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
.titletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
@media (min-width: 768px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
.subtitletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.participatebuttonclass {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.responsive-section {
  display: flex;
  flex-wrap: wrap;
}

.div1 {
  order: 1; /* Initially div1 */
}

.div2 {
  order: 2; /* Initially div2 */
}

@media (min-width: 1200px) {
  .box {
    flex: 1;
  }
}

@media (max-width: 1200px) {
  .div1 {
    order: 1;
  }
  .div2 {
    order: 2;
  }
}

.box {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.left-box {
  order: 1; /* Initially left box */
}

.right-box {
  order: 2; /* Initially right box */
}

img {
  max-width: 100%;
  height: auto;
}
/*  */
.app-info-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.app-info {
  flex: 1;
  min-width: 200px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  background-color: #f0f0f0;
}

.divider-section {
  flex: 1;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.divider-item {
  flex: 1;
  min-width: 150px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  border: 1px solid #ccc;
  margin: 10px; /* Add margin for separation */
}
/*  */
.app-info-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */
}

.app-info {
  flex: 1;
  min-width: 200px; /* Set a minimum width for better responsiveness */
  padding: 20px;
  background-color: #f0f0f0;
}

.divider-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.divider-row {
  display: flex;
  flex: 1;
}

.divider-item {
  flex: 1;
  min-width: 150px;
  padding: 20px;
  border: 0px;
  margin: 10px;
}
.rolling-number {
  font-size: 24px;
  display: inline-flex;
}

.digit {
  transition: transform 0.1s ease-in-out;
  display: inline-block;
}

@media (min-width: 992px) {
  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.w-100 {
  width: 100% !important;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
img {
  vertical-align: middle;
  border-style: none;
}
.carousel-caption-custom-1 {
  padding: 12px 5px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  z-index: 10;
  color: #fff;
  text-align: center;
  position: absolute;
  bottom: 7%;
  width: 88%;
  border-top: 1px #fff solid;
}
.addwrap {
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
/*  */
@media (min-width: 768px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .titletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    color: #ffffff;
  }
}
.titletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 140%;
  color: #ffffff;
}
/*  */
@media (min-width: 768px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
@media (min-width: 940px) {
  .subtitletext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
  }
}
.subtitletext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  color: #ffffff;
}
/*  */
.participatebuttonclass {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #ffffff;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*  */
/*  */
</style>
