<template>
    <main>
        <div class="container" style="text-align: left;" >
            <br>
          <br>
            <img style="width: 80%;" class="mb-4" src="https://www.devalayas.in/assets/images/pp.png" alt="" />
         
          <br>
          <p><h5>Privacy Policy :</h5></p>
          <p class="text-justify">This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011, which requires publishing the rules and regulations, Privacy Policy and Terms of Service for the access or usage of https://devalayas.com ("Website") and DEVALAYA ("App").
            The Website and App are owned and operated by Anek Technologies Private Limited with its place of business at SP OFFICE ROAD, NEAR KOLHAPUR CIRCLE, BELGAUM, KARNATAKA 590010, INDIA,(hereinafter referred to as “We”, “Us” or “Our”)
            This Privacy Policy covers the use of this Website and the App, including providing You the information of our policies regarding the collection, use and disclosure of Personal Information when You use our Service. We are concerned about the privacy of the users (hereinafter referred to as “You”, “Your” or “User”) of Website and App and have provided this privacy policy statement (the “Privacy Policy”) to familiarize You with the manner in which we collect, use and disclose Your information.
            We will not use or share Your information with anyone except as described in this Privacy Policy.
            We use Your Personal Information for providing and improving the Service/s offered by our partners and us. By using the Service, You agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">1. What information do we collect?
            We collect information from You when You register on the Website/App, our social media assets like Facebook, Twitter, Instagram, Linked-in or other digital assets place an order, respond to a survey or communication such as e-mail, or participate in another Website/App feature. When ordering or registering, we may ask You for Your name, e-mail address, mailing address, phone number, credit card, debit card information or other information (“Personal Information”). You may, however, visit the Website anonymously without logging in.
            Like many Websites/App, we use “cookies” to enhance Your experience and gather information about visitors and visits to our Website/App. Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to Your mobile/computer from a Website/App and stored on Your hard drive. We use "cookies" to collect information. You can instruct Your mobile/computer to refuse all cookies or to indicate when a cookie is being sent. However, if You do not accept cookies, You may not be able to use some portions of our Service.
            When You create an Account, You will provide information that could be personally identifiable information, such as Your username, password, email address and mobile number. We may use Your contact information to send You information about our services, but only in accordance with Your contact preferences. Regardless of Your contact preferences, we reserve the right to contact You when we believe it is necessary, such as for account recovery purposes. In conjunction with Your Account, we also display Your profile on the Website/App and on our social media assets like Facebook, Twitter, Instagram, Linked in or other digital assets Your profile displays Your name and other content, such as the items You “Like”, “favourite list”, “usage history” and preferences on the Website/App. All information provided by You to the Website/App may be retained by us indefinitely, even after You terminate Your Account. We may continue to disclose such content to third parties in a manner that does not reveal personally identifiable information, as described in this Privacy Policy.
            Generally, the Website/App automatically collects usage information, such as the number and frequency of visitors to the Website/App. We may use this data in aggregate form, that is, as a statistical measure, but not in a manner that would identify You personally. This type of aggregate data enables us and third parties authorized by us to figure out how often individuals use part of the Services so that we can analyse and improve them.
            We do not collect any financial information, such as Your payment method (valid card number, type, expiration date or other financial information); that information is collected and stored by our licensed third-party payment processing company (the “Payment Processor”), and use and storage of that information is governed by the Payment Processor’s Applicable terms of service and privacy policy.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;2. How do we use and share your information?
            We share Your information with third parties when we believe the sharing is permitted by You, reasonably necessary to offer our services, or when legally required to do so. We use the information we collect to try to provide a safe, efficient, and customized experience. Here are some of the details on how we do that:
            •	To manage the service: We use the information we collect to provide our services and features to You, to measure and improve those services and features, and to provide You with customer support. We use the information to prevent potentially illegal activities, and to enforce our Terms and Conditions.
            •	To contact You: We may contact You and in order to obtain feedback on our services and with service-related announcements from time to time.
            •	To serve personalized advertising to You: We don’t share Your information with advertisers without Your consent. We allow advertisers to choose the characteristics of users, who will see their advertisements and we may use any of the non-personally identifiable attributes we have collected to select the Appropriate audience for those advertisements.
            •	To make Suggestions: We use Your profile information, the addresses You import through our contact importers, and other relevant information to help You connect with Your friends, including making suggestions to You and other users that You connect with on social media.
            •	Downloadable Software: Certain downloadable software Applications that we offer, including, but not limited to, apps for Android, iOS will transmit data to us for us to deliver relevant functionality to our user.
            •	To respond to legal requests and prevent harm: We may disclose information pursuant to Summons, Court Notices, court orders, or other requests (including criminal and civil matters) if we have a good faith belief that the response is required by law.
            •	When You make a payment: When You enter into transactions with or make payments on Website/App, we will only share transaction information with those third parties necessary to complete the transaction and will require those parties to agree to respect the privacy of Your information. Further, if you sign up for a paid subscription of our services, your subscription will be renewed automatically at the end of the initial subscription term on the same terms and conditions based on the subscription package opted by you initially.
            •	Notifications: We may send you notifications from time to time as and when new content is added on to the Website and App and when we introduce new features on the App and Website. If You have opted-in to receive our e-mail newsletter, we may send You periodic e-mails. If You have not opted-in to receive e-mail newsletters, You will not receive these e-mails. Visitors who register or participate in other Website/App features such as marketing programs and ‘members-only’ content will be given a choice whether they would like to be on our e-mail list and receive e-mail communications from us.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;3. How do we protect visitor information?
            We implement a variety of security measures to maintain the safety of Your Personal Information. Your Personal Information is contained behind secured networks and is only accessible by a limited number of persons, working with us who have special access rights to such systems and are required to keep the information confidential. When You place orders, or access Your Personal Information, we use a secured server.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;4. Public Information about Your Activity on the Website/App
            If You choose to provide any personally identifiable information using certain public features of the Website/App, then that information is governed by the privacy settings of those particular features and may be publicly available. Individuals reading such information may use or disclose it to other individuals or entities without our control and without Your knowledge, and search engines may index that information.&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;5. Do we disclose the information we collect to outside parties?
            We do not sell, trade, or otherwise transfer to third-parties, Your personally identifiable information unless we provide You with advance notice in relation to the same and obtain your prior consent. The term “outside parties” does not include Website hosting partners and other parties who assist us in operating the Website/App, conducting our business, or servicing You, so long as those parties agree to keep this information confidential.
            We may also release Your information, when we believe release is Appropriate to comply with the law, enforce our Website/App policies, or protect ours or others’ rights, property or safety.
            Non-personally identifiable visitor information may be provided to other parties for marketing, advertising, benchmarking and internal quality control measures or other uses.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;6. Third party links
            In an attempt to provide You with increased value, we may include third party links on our Website/App. These linked Websites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked Websites. Nonetheless, we seek to protect the integrity of our Website/App and welcome any feedback about these linked Websites (including if a specific link does not work).&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;7. Changes to our policy
            If we decide to change our Privacy Policy, we will post those changes on this page. Policy changes will Apply only to information collected after the date of the change. We recommend you visit this page on a regular basis to stay updated on changes we make from time to time.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;8. Online Policy only
            This online Privacy Policy Applies to all the information collected through our Website/App/Team (whether oral, written or electronic).&nbsp;</p>
<p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;9. Terms and Conditions
            Please also review our Terms of Service section establishing the use, disclaimers, and limitations of liability governing the use of our Website/App.&nbsp;</p>
          <p class="text-justify">&nbsp;</p>
          <p class="text-justify">&nbsp;10. Your consent
            By registering on our Website/App, You consent to our Privacy Policy. </p>
<p>&nbsp;</p>

<h5>Privacy Policy for User Data:</h5><p>&nbsp;</p>
<p>Last updated: October 11, 2022</p><p>&nbsp;</p>
<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
<p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been created with the help of the TermsFeed Privacy Policy Generator.</p><p>&nbsp;</p>
<h5>Interpretation and Definitions</h5><p>&nbsp;</p>
<h5>Interpretation</h5>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
<h5>Definitions</h5>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>
<p><h5>Account</h5> means a unique account created for You to access our Service or parts of our Service.</p>
</li>
<li>
<p><h5>Affiliate</h5> means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p>
</li>
<li>
<p><h5>Application</h5> means the software program provided by the Company downloaded by You on any electronic device, named DEVALAYA</p>
</li>
<li>
<p><h5>Company</h5> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ANEK TECHNOLOGIES PVT LTD, #401, 4th Floor, Clasque HTS, Ayodhya Nagar, Belgaum.</p>
</li>
<li>
<p><h5>Country</h5> refers to: Karnataka,  India</p>
</li>
<li>
<p><h5>Device</h5> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</p>
</li>
<li>
<p><h5>Personal Data</h5> is any information that relates to an identified or identifiable individual.</p>
</li>
<li>
<p><h5>Service</h5> refers to the Application.</p>
</li>
<li>
<p><h5>Service Provider</h5> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p>
</li>
<li>
<p><h5>Usage Data</h5> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
</li>
<li>
<p><h5>You</h5> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p>
</li>
</ul><p>&nbsp;</p>
<h5>Collecting and Using Your Personal Data</h5><p>&nbsp;</p>
<h5>Types of Data Collected</h5>
<h5>Personal Data</h5>
<p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Phone number</p>
</li>
<li>
<p>Address, State, Province, ZIP/Postal code, City</p>
</li>
<li>
<p>Usage Data</p>
</li>
</ul>
<p>&nbsp;</p><h5>Usage Data</h5><p>&nbsp;</p>
<p>Usage Data is collected automatically when using the Service.</p>
<p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
<p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
<p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
<p>&nbsp;</p><h5>Information Collected while Using the Application</h5><p>&nbsp;</p>
<p>While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
<ul>
<li>Information regarding your location</li>
<li>Information from your Device's phone book (contacts list)</li>
<li>Pictures and other information from your Device's camera and photo library</li>
</ul>
<p>We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
<p>You can enable or disable access to this information at any time, through Your Device settings.</p>
<p>&nbsp;</p><h5>Use of Your Personal Data</h5><p>&nbsp;</p>
<p>The Company may use Personal Data for the following purposes:</p>
<ul>
<li>
<p><h5>To provide and maintain our Service</h5>, including to monitor the usage of our Service.</p>
</li>
<li>
<p><h5>To manage Your Account:</h5> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</p>
</li>
<li>
<p><h5>For the performance of a contract:</h5> the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</p>
</li>
<li>
<p><h5>To contact You:</h5> To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</p>
</li>
<li>
<p><h5>To provide You</h5> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</p>
</li>
<li>
<p><h5>To manage Your requests:</h5> To attend and manage Your requests to Us.</p>
</li>
<li>
<p><h5>For business transfers:</h5> We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</p>
</li>
<li>
<p><h5>For other purposes</h5>: We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</p>
</li>
</ul>
<p>We may share Your personal information in the following situations:</p>
<ul>
<p>&nbsp;</p><li><h5>With Service Providers:</h5> We may share Your personal information with Service Providers to monitor and analyze the use of our Service,  to contact You.</li>
<p>&nbsp;</p><li><h5>For business transfers:</h5> We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</li>
<p>&nbsp;</p><li><h5>With Affiliates:</h5> We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</li>
<p>&nbsp;</p><li><h5>With business partners:</h5> We may share Your information with Our business partners to offer You certain products, services or promotions.</li>
<p>&nbsp;</p><li><h5>With other users:</h5> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
<p>&nbsp;</p><li><h5>With Your consent</h5>: We may disclose Your personal information for any other purpose with Your consent.</li>
</ul>
<p>&nbsp;</p><h5>Retention of Your Personal Data</h5>
<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
<p>&nbsp;</p><h5>Transfer of Your Personal Data</h5>
<p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
<p>&nbsp;</p><h5>Delete Your Personal Data</h5>
<p>You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
<p>Our Service may give You the ability to delete certain information about You from within the Service.</p>
<p>You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
<p>Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
<p>&nbsp;</p><h5>Disclosure of Your Personal Data</h5>
<p>&nbsp;</p><h5>Business Transactions</h5>
<p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<p>&nbsp;</p><h5>Law enforcement</h5>
<p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
<p>&nbsp;</p><h5>Other legal requirements</h5>
<p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
<ul>
<li>Comply with a legal obligation</li>
<li>Protect and defend the rights or property of the Company</li>
<li>Prevent or investigate possible wrongdoing in connection with the Service</li>
<li>Protect the personal safety of Users of the Service or the public</li>
<li>Protect against legal liability</li>
</ul>
<p>&nbsp;</p><h5>Security of Your Personal Data</h5>
<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
<p>&nbsp;</p><h5>Children's Privacy</h5>
<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
<p>&nbsp;</p><h5>Links to Other Websites</h5>
<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
<p>&nbsp;</p><h5>Changes to this Privacy Policy</h5>
<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<p>&nbsp;</p><h5>Contact Us</h5>
<p>If you have any questions about this Privacy Policy, You can contact us:</p>
<ul>
<li>
<p>By email: info@devalayas.com</p>
</li>
<li>
<p>By visiting this page on our website: <a href="http://www.devalayas.in" rel="external nofollow noopener" target="_blank">http://www.devalayas.in</a></p>
<p>Thank you!&nbsp;</p>
</li>
</ul>
<div class="row"> </div>
      </div>
    </main>
</template>
<script>

export default {  
    mounted(){
      window.scrollTo(0,0);
      let storedMobileNumber = localStorage.getItem("mobileNumber");
    if (
      storedMobileNumber != null &&
      storedMobileNumber != "null" &&
      storedMobileNumber != "+919080706050"
    ) {
      this.$store.dispatch("setsignInMbl", true);
    }
       },
      };

</script>